import { MultiSelect } from 'react-multi-select-component'

import { companySizeOptions } from '@/utils/constants'
import { SetStateAction } from 'react'

type CompanySizeDropdownProps = {
  selectedCompanySize: string[],
  setSelectedCompanySize: React.Dispatch<SetStateAction<string[]>>
}

export const CompanySizeDropdown = ({ selectedCompanySize, setSelectedCompanySize }: CompanySizeDropdownProps) => {

  const handleChange = (selected) => {
    setSelectedCompanySize([selected[selected.length - 1].value])
  }

  const selectOptions =
    selectedCompanySize
      .map(value => companySizeOptions.find(option => option.value === value))
      .filter(Boolean)

  return (
    <div className='relative cursor-pointer'>
      {selectedCompanySize.length > 0 &&
        <span className='absolute -right-1 top-[-3px] z-10 flex size-3 rounded-full bg-ocean-blue' />
      }
      <MultiSelect
        labelledBy='Your Company Size'
        options={companySizeOptions}
        value={selectOptions}
        disableSearch={true}
        valueRenderer={() => 'Your Company Size'}
        className='text-xs'
        hasSelectAll={false}
        onChange={handleChange}
        ClearSelectedIcon={null}
      />
    </div>
  )
}