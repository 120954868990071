import { useState } from 'react'
import Modal from '@components/atoms/modals/Modal'
import ImageCropComponent from '@components/molecules/modals/ImageCropComponent'
import Button from '@/components/atoms/buttons/Button'
import { toast } from 'react-toastify'
import ErrorMessage from '@/components/atoms/Toast/ErrorMessage'
import { defaultToastOptions } from '@/utils/commonToast'

type ImagePreviewModalProps = {
  openModal: boolean
  setOpenModal: (open: boolean) => void
  src: string
  handleFileChange: (
    file: File | null,
    type: string,
    maxSize: number,
    urlSetter: (url: string) => void
  ) => void
  previewUrlSetter: (url: string) => void
  mobilePreviewUrlSetter: (url: string) => void
  MAX_HERO_IMAGE_SIZE: number
  selectedInput: 'desktop' | 'mobile'
}

const ImagePreviewModal = ({
  openModal,
  setOpenModal,
  src,
  handleFileChange,
  previewUrlSetter,
  mobilePreviewUrlSetter,
  MAX_HERO_IMAGE_SIZE,
  selectedInput,
}: ImagePreviewModalProps) => {
  const [croppedFile, setCroppedFile] = useState<File | null>(null);

  const handleSave = async () => {
    if (!croppedFile) {
      toast.error(<ErrorMessage message={'No file selected.'}  />),
      defaultToastOptions('red-200')
      return
    }
    const urlSetter = selectedInput === 'desktop' ? previewUrlSetter : mobilePreviewUrlSetter
    handleFileChange(croppedFile, `${selectedInput === 'desktop' ? 'hero_image' : 'mobile_hero_image'}`, MAX_HERO_IMAGE_SIZE, urlSetter)
    setOpenModal(false)
  }

  return (
    <Modal
      isOpen={openModal}
      onClose={() => setOpenModal(false)}
      closeStyle='top-3'
      className={`!mx-[20px] ${selectedInput === 'desktop' ? 'w-[1200px]' : 'w-[700px]'} h-[700px] gap-5 rounded-xl bg-white px-9 pb-6 pt-12 md:mt-0  md:h-[600px] `}
    >
      <h2 className='mt-[-35px] text-2xl font-bold text-gray-600 mb-4'>Crop your {selectedInput === 'desktop' ? 'desktop' : 'mobile'} photo</h2>
      <p className='text-sm text-gray-600 mb-12'>For the best result we recommend using images at least {selectedInput === 'desktop' ? '1200px' : '600px'} wide.</p>
      <div className='mt-2'>
        <ImageCropComponent src={src} setCroppedFile={setCroppedFile} selectedInput={selectedInput} />
      </div>
      <div className='mt-4 flex flex-row justify-end gap-3'>
        <Button variant='gray' size='md' onClick={() => setOpenModal(false)}> Cancel </Button>
        <Button variant={`${croppedFile ? 'teal' : 'disable'}`} size='md' onClick={handleSave}> Save</Button>
      </div>
    </Modal>
  )
}

export default ImagePreviewModal
