import React, { useEffect, useMemo, useState } from 'react';
import Form, { useToken } from '@/components/atoms/forms/Form';
import { useForm, Link, usePage, router } from '@inertiajs/react';
import Layout from '@components/organisms/layouts/Layout';
import Button from '@/components/atoms/buttons/Button';
import Input from '@/components/atoms/inputs/Input';
import { mixpanel } from '@/utils/mixpanel';
import axios from 'axios';
import Meta from '@/components/atoms/widgets/Meta';
import { useTracking } from 'react-tracking'
import PageTitle from '@/components/atoms/widgets/PageTitle';
import { addSolutionUrl } from '@/utils/constants'
import window from 'global'

type SignInProps = {
  form_path: string;
  // @TODO: Figure out how to type this
  user: any;
  role?: string;
  fallback_url: string;
  wp_login?: boolean;
};

export type SharedPageProps = {
  flash: {
    alert?: string;
    notice?: string;
  };
  currentUser?: schema.User;
};

export default function SignIn({ ...props }: SignInProps) {
  const { form_path, role, fallback_url, wp_login  } = props;
  const token = useToken();
  const { data, setData, processing } = useForm({
    email: '',
    password: '',
  });
  const pageProps = usePage<SharedPageProps>().props;
  const { flash } = pageProps;
  const [alert, setAlert] = useState(flash?.alert);
  const tracking = useTracking()

  const isCustomer = role === 'customer'
  const visit_route = isCustomer ? '/customer/login' : '/login'

  const isFlashAlertNotFoundInDatabase = useMemo(() => {
    if (typeof alert == 'string') {
      const regex =
        /This email and password combination doesn't match our records/g;
      return regex.test(alert);
    } else {
      return false;
    }
  }, [alert]);

  const isFlashAlertUnconfirmed = useMemo(() => {
    if (typeof alert == 'string') {
      const regex =
        /Your email address must be confirmed before your first login/g;
      return regex.test(alert);
    } else {
      return false;
    }
  }, [alert]);

  const onSuccess = () => {
    localStorage.setItem('rmc_logged_in', 'true')
    window.location.href = fallback_url
  }

  const submit = async (event) => {
    event.preventDefault();
    const response = await axios.post(
      form_path,
      {
        user: {
          email: data.email,
          password: data.password,
        },
      },
      {
        headers: {
          'X-CSRF-Token': token,
        },
      }
    );
    if (response.data.success) {
      const currentUser = response.data.current_user;
      mixpanel.identify(currentUser.email);
      mixpanel.track('Log In Complete', {
        Company: currentUser.company,
        'Entry Point': 'Login Page',
      });
      tracking.trackEvent({
        event_name: 'session_start', 
        page_url: window.location.pathname,
        event_loggable_type: "RmcEvents::RmcEvent",
        additional_attributes: { event_type: 'session_start' }
      })
      localStorage.setItem('rmc_logged_in_user_id', currentUser?.id)
      onSuccess()
    } else {
      router.visit(visit_route, {data: {
        fallbackUrl: fallback_url
      }});
    }
  };

  useEffect(() => {
    mixpanel.track('Log In Start', {
      'Entry Point': 'Login Page',
    });
  }, []);

  return (
    <Layout>
      <PageTitle title='Log In to Your Talivity Marketplace Account' />
      <Meta
        title="Log In to Your Talivity Marketplace Account"
        description="Log in to your Talivity Marketplace account to learn about the top talent acquisition tech vendors, formerly RecruitmentMarketing.com."
      />
      <div className="flex w-full flex-1 flex-col sm:max-w-[600px]">
        <div>
          <div className="mb-5 flex flex-col text-2xl font-bold text-black sm:mb-3 sm:flex-row sm:justify-center sm:gap-2 lg:mb-3 lg:mt-[40px]">
            <p>Welcome back!</p>
            <p>Please log in.</p>
          </div>
          {!isCustomer &&
            <div className="mb-8 text-md sm:mb-12 sm:text-center lg:mb-[60px]">
              <p>
                Want your solution listed?{' '}
                <a
                  href={addSolutionUrl}
                  className="border-b-[2px] border-light-gray-hover-2 font-semibold"
                  target="_blank"
                  rel="noreferrer"
                >
                  Add or Verify Your Solution
                </a>
              </p>
            </div>
          }
        </div>
        <div className="flex flex-1 flex-col">
          {/* @TODO: Create a shared component to handle flash alerts using the `usePage` hook */}
          <Form onSubmit={submit}>
            <div className="flex h-full flex-1 flex-col justify-between lg:justify-start">
              <div
                className={`flex flex-col text-black ${
                  !isFlashAlertUnconfirmed && 'lg:mb-14'
                }`}
              >
                <Input
                  type="email"
                  label="Email address"
                  errors={isFlashAlertNotFoundInDatabase && alert}
                  value={data.email}
                  onChange={(e) => setData('email', e.target.value)}
                  containerClass="mb-7"
                  labelClass='!text-md'
                />
                {/* {errors.email && <div>{errors.email}</div>} */}
                <Input
                  label="Password"
                  type="password"
                  errors={isFlashAlertNotFoundInDatabase && alert}
                  value={data.password}
                  containerClass="mb-2"
                  onChange={(e) => setData('password', e.target.value)}
                  labelClass='!text-md'
                />
                {isFlashAlertNotFoundInDatabase && (
                  <p className="mt-1 mb-2 text-[13px] text-danger-red">
                    {alert}
                  </p>
                )}
                <div className="mt-1 text-right text-black">
                  {' '}
                  <Link replace href="/users/password/new">
                    Forgot Password?
                  </Link>
                </div>
                <div className="px-2 py-4 text-center text-xs">
                  Trouble logging in? Please contact
                  <a href='mailto:info@talivity.com' className='underline text-dark-blue pl-1'>info@talivity.com</a> for assistance
                </div>
              </div>
              {isFlashAlertUnconfirmed && (
                <div className="my-5 flex flex-col items-center bg-pink-tint-2 px-4 py-2.5 text-center text-lg text-black">
                  <p>{alert}</p>
                  <p>Please check your inbox for the confirmation link.</p>
                  <p>
                    Contact{' '}
                    <a
                      className="cursor-pointer underline"
                      href="mailto:info@talivity.com"
                    >
                      info@talivity.com
                    </a>{' '}
                    with any questions.
                  </p>
                </div>
              )}
              <div className="flex flex-col items-center">
              {isCustomer && <Link className='text-slate-400 cursor-pointer' href={`/users/sign_up/?fallbackUrl=${fallback_url}`}>Don't have an account? Create a customer account.</Link>}
                <Button
                  className="w-full sm:w-[353px]"
                  type="submit"
                  disabled={processing || !data.email || !data.password}
                  size="md"
                  variant="primary"
                >
                  Login
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </Layout>
  );
}
