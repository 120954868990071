import React, { useEffect, useState } from 'react'

import { router } from '@inertiajs/react'
import guidePhoto from 'app/assets/images/guidePhoto.webp'
import { FiPower, FiUsers, FiUserMinus, FiLayers, FiCheck, FiStar } from 'react-icons/fi'

import Button from '@/components/atoms/buttons/Button'
import { MLP_PROMO_LINK } from '@/utils/constants'
import { SolutionCompletenessGradient } from '@/utils/constants/styles'
import { useMobileDetect } from '@/utils/detectMobile'

export default function SolutionCompletenessRow({ solution }) {
  const profileScore = solution.profile_score
  const totalTabs = 8
  const minPercentage = 12.5
  const filledPercentage = Math.min((profileScore / (totalTabs * minPercentage)) * 100)

  const [BannerIcon, setBannerIcon] = useState(<FiPower />)
  const [banner, setBanner] = useState('')
  const [subheading, setSubheading] = useState('')
  const [cta, setCta] = useState('')
  const [ctaLink, setCtaLink] = useState('')
  const isMobile = useMobileDetect({ screenSize: 550 })

  const setGuideData = () => {
    if (profileScore <= minPercentage) {
      setBanner("You're missing out on inbound leads")
      setSubheading("Tell potential buyers about your solution and why it's the right fit for them")
      setCta('Launch Profile')
      setCtaLink(`/solutions/${solution.slug}`)
      setBannerIcon(<FiPower />)
    }
    else if (profileScore >= minPercentage && !solution.free_sections_filled) {
      setBanner("Missing content is costing you customers")
      setSubheading('Your profile is lacking key information that buyers want to know')
      setCta('Update Profile')
      setCtaLink(`/solutions/${solution.slug}`)
      setBannerIcon(<FiUsers />)
    }
    else if (solution.free_sections_filled && !solution.paid_solution) {
      setBanner("You're losing potential customers")
      setSubheading('Premium profiles get up to 10x more views')
      setCta('Upgrade Profile')
      setCtaLink(MLP_PROMO_LINK)
      setBannerIcon(<FiUserMinus />)
    }
    else if (solution.paid_solution && profileScore < 100) {
      setBanner("You're missing out on leads")
      setSubheading('Complete profiles generate the most leads')
      setCta('Update Profile')
      setCtaLink(`/solutions/${solution.slug}`)
      setBannerIcon(<FiLayers />)
    }
  }

  useEffect(() => {
    setGuideData()
  }, [])

  const SolutionBar = () => {
    return (
      <div className={`flex flex-col gap-6 ${!isMobile ? 'w-[450px]' : 'w-full'}`}>
        <div className='flex gap-2'>
          <p className='text-carbon-gray mt-5 text-xl'>Profile Score:</p>
          <p className='mt-5 text-xl font-bold text-black'>{profileScore}%</p>
        </div>
        <div className='relative flex w-full items-center justify-center'>
          <div
            className={SolutionCompletenessGradient(filledPercentage).container}
            style={SolutionCompletenessGradient(filledPercentage).gradientBackground}
          >
            <div className='absolute left-[-1%] flex size-[30px] -translate-y-2 items-center justify-center rounded-full border border-black bg-white shadow-md'>
              <FiCheck className='text-black' />
            </div>
            <div
              className={`absolute left-[55%] flex size-[30px] -translate-y-2 items-center justify-center rounded-full bg-white shadow-md ${profileScore >= 62.5 ? 'border border-black text-black' : 'text-gray-400'}`}
            >
              <FiCheck />
            </div>
            <div
              className={`absolute right-[-1%] flex size-[30px] -translate-y-2 items-center justify-center rounded-full shadow-md ${profileScore < 100 ? 'border bg-white text-gray-400' : 'bg-maastricht-blue text-robin-blue'}`}
            >
              <FiStar />
            </div>
          </div>
        </div>
        <Button
          size='md'
          variant='secondary'
          className='mt-7 flex h-[48px] w-[246px] items-center justify-center'
          onClick={() => router.visit(`/solutions/${solution.slug}`)}
        >
          Edit Profile
        </Button>
      </div>
    )
  }

  const SolutionGuide = () => {
    return (
      <div
        className='flex min-h-[186px] w-auto items-center rounded-2xl bg-slate-500 sm:w-fit lg:w-[550px]'
        style={{
          background: `linear-gradient(0deg, rgba(3, 25, 49, 0.64) 0%, rgba(3, 25, 49, 0.64) 100%), 
               linear-gradient(0deg, rgba(3, 25, 49, 0.64) 0%, rgba(3, 25, 49, 0.64) 100%), 
               url(${guidePhoto}) lightgray 50% / cover no-repeat`,
          backgroundBlendMode: 'normal, multiply, normal',
        }}
      >
        <div className='flex w-[480px] gap-8 p-6'>
          {!isMobile &&
            React.cloneElement(BannerIcon, { className: 'size-[48px] text-pink stroke-1' })
          }
          <div className='flex flex-col gap-6'>
            <div>
              <p className='text-xl font-semibold text-white'>{banner}</p>
              <p className='text-md text-gray-suit '>{subheading}</p>
            </div>
            <Button
              variant='white'
              className='flex h-[40px] w-[151px] items-center justify-center text-[15px] font-semibold text-outer-space'
              onClick={() => window.location.href=  ctaLink}
            >
              {cta}
            </Button>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className='my-5 flex flex-col justify-between gap-12 border-b-[1px] pb-5 max-lg:flex-row'>
      <SolutionBar />
      {profileScore !== 100 && (
        <SolutionGuide />
      )
      }
    </div>
  )
}

