import Button from '@/components/atoms/buttons/Button';
import { useToken } from '@/components/atoms/forms/Form';
import { router, useForm } from '@inertiajs/react';
import React, { useState, lazy } from 'react';
import { toast } from 'react-toastify';
import editPencilBlackIcon from '../../../../assets/images/edit-pencil.svg';
import OurCustomers  from './OurCustomers';
import EditOurCustomers from './EditOurCustomers';
import clsx from 'clsx';
import CustomerIcon from '../../../../assets/images/customer-icon.svg';
const SuccessMessage = lazy(() => import('@/components/atoms/Toast/SuccessMessage'));
import { defaultToastOptions } from '@/utils/commonToast';
import ErrorMessage from '@/components/atoms/Toast/ErrorMessage';
import { putRequest } from '@/utils/axiosRequests';

const OurCustomersSection = ({
  customers,
  solution,
  current_user,
  solutionOwner,
  isRmcAdmin
}) => {
  const [editCustomer, setIsEditCustomer] = useState(false);
  const [isLoading, setIsLoading] = useState(false)
  const token = useToken();

  const { data, setData } = useForm({
    customers_attributes: customers,
  });

  const handleEditClick = () => {
    setIsEditCustomer(true);
  };

  const isInavlidTitleCustomerExists = () => {
    const invalidTitleCustomers = data.customers_attributes.filter(
      (customer) => customer.title.trim('') === '' && customer.logo !== null
    );
    if (invalidTitleCustomers.length > 0) {
      return true;
    }
    return false;
  }

  const handleSubmit = async () => {
    try {
      setIsLoading(true)
      if(isInavlidTitleCustomerExists()){
        toast(
          <ErrorMessage message="Title Can't be Blank or Empty" />,
          defaultToastOptions('red-200')
        );
        return;
      }
      const formData = new FormData();
      data.customers_attributes.forEach((customer, index) => {
        if (customer.id) {
          formData.append(
            `solution[customers_attributes][${index}][id]`,
            customer.id
          );
        }
        if (customer.logo_url === null && customer.logo !== null) {
          formData.append(
            `solution[customers_attributes][${index}][logo]`,
            customer.logo
          );
        }
        formData.append(
          `solution[customers_attributes][${index}][title]`,
          customer.title
        );
        if (customer._destroy) {
          formData.append(
            `solution[customers_attributes][${index}][_destroy]`,
            customer._destroy
          );
        }
      });

      const response = await putRequest(`/solutions/${solution.id}`, formData, token)
      if (response.status === 200) {
        toast(
          <SuccessMessage
            message='Your changes have been
            saved.'
          />,
          defaultToastOptions('teal-tint-2')
        );
        setTimeout(() => {
          router.visit(`/solutions/${solution.slug}`);
        }, 1000);
      }
    } catch (error) {
      setIsLoading(true)
      toast(
        <ErrorMessage message='Something Went Wrong! Try Again.' />,
        defaultToastOptions('red-200')
      );
    } finally {
      setIsLoading(false)
    }
  };

  const handleBack = () => {
    router.visit(`/solutions/${solution.slug}`);
  };

  const shouldShowEmptyCustomersSection = 
    current_user &&
    current_user?.id === solutionOwner?.id &&
    customers?.every((obj) => obj.id === null)

  return (
    <div className='sm:mt-[6rem] mt-[2rem]'>
      {((shouldShowEmptyCustomersSection || isRmcAdmin) || !customers?.every((obj) => obj.id === null)) && (
        <div>
          {
            <div className='flex justify-between'>
              <div className='flex items-center gap-2'>
                <img src={CustomerIcon} className='w-5 h-5' loading='lazy' />
                <h2 className='sm:text-xl text-lg font-bold'>Our Customers</h2>
              </div>
              {((current_user && current_user?.id == solutionOwner?.id) || isRmcAdmin) && (
                <div>
                  {!editCustomer ? (
                    <div className='rounded-full bg-white p-2'>
                      <img
                        src={editPencilBlackIcon}
                        alt='pencil icon'
                        onClick={handleEditClick}
                        className='w-[35px] stroke-white'
                        style={{ cursor: 'pointer' }}
                        loading='lazy'
                      />
                    </div>
                  ) : (
                    <div className='ml-auto flex gap-2'>
                      <Button
                        type='submit'
                        size='md'
                        variant='teal'
                        className='flex h-12 w-20 items-center justify-center rounded-[12px] px-4 py-3'
                        onClick={handleSubmit}
                        isLoading={isLoading}
                      >
                        {isLoading ? 'Saving...' : 'Save'}
                      </Button>
                      <Button
                        type='button'
                        size='md'
                        variant='gray'
                        className='flex h-12 w-20 items-center justify-center rounded-[12px] px-4 py-3'
                        onClick={() => handleBack()}
                        isLoading={isLoading}
                      >
                        Cancel
                      </Button>
                    </div>
                  )}
                </div>
              )}
            </div>
          }
          <div
            className={clsx('rounded-xl mt-4', {
              'border-[1.5px]': editCustomer,
            })}
          >
            {editCustomer ? (
              <EditOurCustomers
                data={data}
                setData={setData}
              />
            ) : (
              <OurCustomers
                customers={customers}
                current_user={current_user}
                solutionOwner={solutionOwner}
                isRmcAdmin={isRmcAdmin}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default OurCustomersSection;
