import { useEffect, useRef } from 'react'

import { toast } from 'react-toastify'

import emptyLogo from '../../../../assets/images/lg-logoPlaceHolder.svg'
import solutionLogoPlaceholder from '../../../../assets/images/solution-logo-placeholder.svg'
import whiteCloseIcon from '../../../../assets/images/white-close-icon-small.svg'
import Button from '../../atoms/buttons/Button'

import HybridLink from '@/components/atoms/widgets/HybridLink'
import { defaultToastOptions } from '@/utils/commonToast'

type CompareSolutionTabProps = {
  solutionsToCompare: schema.Solution[]
  setSolutionsToCompare: React.Dispatch<
    React.SetStateAction<schema.Solution[]>
  >
}

export default function CompareSolutionTab({
  solutionsToCompare,
  setSolutionsToCompare,
}: CompareSolutionTabProps) {
  const solutionsListSize = useRef(solutionsToCompare.length)

  const removeSolution = (solution) => {
    const updatedSolutionsToCompare = solutionsToCompare.filter((s) => {
      if (solution.name === s.name) return false
      return true
    })
    setSolutionsToCompare(updatedSolutionsToCompare)
  }
  const getSlugsOfSolutions = (solutions) => {
    const ids = solutions.map((s) => {
      return s.slug
    })
    return ids
  }

  const checkSolutionList = () => {
    if (solutionsListSize.current !== solutionsToCompare.length && solutionsToCompare.length === 4) {
      toast(
        <div>
          <span className='font-bold'>Warning! </span> {'You can save maximum 4 solutions'}
        </div>,
        defaultToastOptions('red-200')
      )
    }
    solutionsListSize.current = solutionsToCompare.length
  }

  useEffect(() => {
    checkSolutionList()
  }, [solutionsToCompare])

  return (
    <div className='flex flex-col items-center justify-between gap-6 px-4 pb-10 pt-5 sm:flex-row sm:px-10 sm:py-5 md:px-20'>
      <div className='flex w-full items-center justify-between sm:w-auto'>
        <p className='sm:hidden'>Compare Solutions</p>
        <p
          className='ml-[10px] cursor-pointer text-xs underline sm:hidden'
          onClick={() => setSolutionsToCompare([])}
        >
          Remove All
        </p>
      </div>
      <div className='flex items-center gap-[10px]'>
        {Array.from({ length: 4 }, (_, i) => (
          <div key={i}>
            {solutionsToCompare[i] ? (
              <div className='size-[72px] sm:size-[44px]'>
                <img
                  src={
                    solutionsToCompare[i].logo_url || solutionLogoPlaceholder
                  }
                  alt='solution logo'
                  className={'size-full cursor-pointer rounded-full'}
                  onClick={() => window.open(`/solutions/${solutionsToCompare[i].slug}`, '_blank')}
                />
                <div
                  className='relative right-[-50px] top-[-75px] flex size-[20px] cursor-pointer items-center justify-center rounded-xl bg-dark-blue sm:right-[-30px] sm:top-[-50px]'
                  onClick={() => removeSolution(solutionsToCompare[i])}
                >
                  <img src={whiteCloseIcon} alt='close icon' />
                </div>
              </div>
            ) : (
              <img src={emptyLogo} alt='empty logo' className='sm:size-[44px]' />
            )}
          </div>
        ))}
        <p
          className='ml-[10px] hidden cursor-pointer text-xs underline sm:block'
          onClick={() => setSolutionsToCompare([])}
        >
          Remove All
        </p>
      </div>
      <div className='flex w-full justify-end sm:w-auto'>
        {solutionsToCompare.length > 1 ? (
          <HybridLink
            href={`/solutions/compare_solution/${getSlugsOfSolutions(solutionsToCompare)}`}
            className='text-sm sm:w-auto'
            isExternal={false}
          >
            <Button
              variant={solutionsToCompare?.length === 1 ? 'tertiary' : 'primary'}
              className='p-3'
              disabled={solutionsToCompare?.length <= 1}
              trackBtnClick={true}
            >
              Compare Now
            </Button>
          </HybridLink>
        ) : (
          <Button
            variant={solutionsToCompare?.length === 1 ? 'disable' : 'primary'}
            className='p-3'
            disabled={solutionsToCompare?.length <= 1}
            trackBtnClick={true}
          >
            Compare Now
          </Button>
        )}
      </div>
    </div>
  )
}
