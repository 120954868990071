import { useState, useEffect } from 'react'

import solutionLogoPlaceholder from '../../../../assets/images/solution-logo-placeholder.svg'
import SolutionStarRating from '../solution/SolutionStarRating'

import { SaveForLaterButton } from '@/components/atoms/compareSolution/SaveForLaterButton'
import HybridLink from '@/components/atoms/widgets/HybridLink'

type SimilarSolutionCardProps = {
  solution: schema.Solution
  solutionsToCompare?: schema.Solution[]
  setSolutionsToCompare?: React.Dispatch<React.SetStateAction<schema.Solution[]>>
}

const SimilarSolutionCard = ({
  solution,
  solutionsToCompare,
  setSolutionsToCompare,
}: SimilarSolutionCardProps) => {
  const [isChecked, setIsChecked] = useState(false)

  useEffect(() => {
    const solutionNames = solutionsToCompare?.map((s) => {
      return s.name
    })
    if (solutionNames?.includes(solution.name)) {
      setIsChecked(true)
    } else {
      setIsChecked(false)
    }
  }, [solutionsToCompare])

  return (
    <div className='flex max-h-[120px] flex-col rounded-lg shadow-service-hero'>
      <HybridLink href={`/solutions/${solution.slug}`} >
        <div className='flex h-[20px] self-end'>
          {solution.is_featured &&
            <p className='rounded-tr-lg rounded-bl-lg bg-ocean-blue px-[8px] py-[2px] text-[10.7px] font-semibold text-white tracking-[1px]'>
              Featured
            </p>
          }
        </div>
        <div className='flex flex-col gap-2 px-2 pb-2'>
          <div className='flex gap-3'>
            <div className='flex max-h-[50px] min-h-[50px] min-w-[50px] max-w-[50px]'>
              <img
                className='max-h-[50px] min-h-[50px] min-w-[50px] max-w-[50px] rounded-full'
                src={solution.logo_url || solutionLogoPlaceholder}
                alt='logo'
                rel='nofollow'
              />
            </div>
            <div className='flex flex-col'>
              <p className='mt-1 text-md font-bold'>
                {solution.name}
              </p>
              {solution.ratings_count >= 0 && (
                <SolutionStarRating rating={solution.rating} ratingCount={solution.ratings_count} />
              )}
            </div>
          </div>

        </div>
      </HybridLink>
        {solutionsToCompare && (
          <div className='mb-2 mr-2 flex items-center gap-1 self-end'>
            <SaveForLaterButton
              solutionsToCompare={solutionsToCompare}
              isChecked={isChecked}
              setIsChecked={setIsChecked}
              setSolutionsToCompare={setSolutionsToCompare}
              solution={solution}
            />
          </div>
        )}
    </div>
  )
}

export default SimilarSolutionCard
