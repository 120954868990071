const IntroSection = () => {
  return (
    <>
      <div className="hidden xl:mx-auto xl:block lg:block md:block md:px-10 xl:px-46 lg:px-36 xl:mx-[2.188rem]">
        <div className="max-w-[964px] mx-auto hidden sm:block bg-white rounded-md"
        style={{ boxShadow: '0px 2px 20px 0px rgba(0, 0, 0, 0.08)' }}>
          <div className="flex justify-evenly xl:py-[2rem] py-[0.5rem] xl:flex-row lg:flex-row items-center text-outer-space">
            <div className="lg:px-10 md:px-8 text-center">
              <p className='text-[14px] font-normal'>Join</p>
              <h2 className='xl:text-[48px] md:text-[28px] text-talivity-teal font-extrabold -tracking-[1.399px]'>100K+</h2>
              <p className='xl:text-[14px] text-2xs font-normal leading-tight'>talent professionals <br /> using our platform.</p>
            </div>
            <div className="h-[6.8rem] my-2.5 w-[1.021px] bg-[#41CDA7] mx-4"></div>
            <div className="lg:px-10 md:px-8 text-center">
              <p className='text-[14px] font-normal'>Discover</p>
              <h2 className='xl:text-[48px] md:text-[28px] font-extrabold -tracking-[1.399px] text-talivity-teal'>1,500+</h2>
              <p className='xl:text-[14px] text-2xs font-normal leading-tight'>talent solutions, verified <br /> and listed on The Marketplace.</p>
            </div>
            <div className="h-[6.8rem] my-2.5 w-[1.021px] bg-[#41CDA7] mx-4"></div>
            <div className="lg:px-10 md:px-8 text-center">
              <p className='text-[14px] font-normal'>Explore</p>
              <h2 className='xl:text-[48px] md:text-[28px] font-extrabold -tracking-[1.399px] text-talivity-teal'>500+</h2>
              <p className='xl:text-[14px] text-2xs font-normal leading-tight'>curated industry events, <br /> discounted for members.</p>
            </div>
          </div>
        </div>
      </div>
      {/* Mobile Intro Bottom Section */}
      <div className="rounded-[8px] md:hidden flex flex-col items-center w-[315px] h-[560px] mx-auto py-2" style={{ boxShadow: '0px 2px 20px 0px rgba(0, 0, 0, 0.08)' }}>
        <div className="p-4 text-center">
          <p className='text-outer-space text-[14px]'>Join</p>
          <h2 className='text-[48px] text-talivity-teal font-extrabold -tracking-[0.877px]'>100K+</h2>
          <p className='text-outer-space text-[14px] mx-auto'>talent professionals <br /> using our platform.</p>
        </div>
        <div className="w-[82px] my-2.5 h-[1px] bg-[#41CDA7]"></div>
        <div className="p-4 text-center">
          <p className='text-outer-space text-[14px]'>Discover</p>
          <h2 className='text-[48px] font-extrabold -tracking-[0.877px] text-talivity-teal'>1,500+</h2>
          <p className='text-outer-space text-[14px] mx-auto'>talent solutions, verified <br /> and listed on The Marketplace.</p>
        </div>
        <div className="w-[82px] my-2.5 h-[1px] bg-[#41CDA7]"></div>
        <div className="p-4 text-center">
          <p className='text-outer-space text-[14px]'>Explore</p>
          <h2 className='text-[48px] font-extrabold -tracking-[0.877px] text-talivity-teal'>500+</h2>
          <p className='text-outer-space text-[14px] mx-auto'>curated industry events,<br /> discounted for members.</p>
        </div>
      </div>
    </>
  )
}

export default IntroSection;
