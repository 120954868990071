import { useState, useEffect } from 'react'

import solutionLogoPlaceholder from '../../../../assets/images/solution-logo-placeholder.svg'
import SolutionStarRating from '../solution/SolutionStarRating'

import { SaveForLaterButton } from '@/components/atoms/compareSolution/SaveForLaterButton'
import HybridLink from '@/components/atoms/widgets/HybridLink'
import minifyString from '@/utils/minifyString'

type CategorySolutionCardProps = {
  solution: schema.Solution
  solutionsToCompare?: schema.Solution[]
  setSolutionsToCompare?: React.Dispatch<React.SetStateAction<schema.Solution[]>>
}

const CategorySolutionCard = ({
  solution,
  solutionsToCompare,
  setSolutionsToCompare,
}: CategorySolutionCardProps) => {
  const [isChecked, setIsChecked] = useState(false)
  const [showMore, setShowMore] = useState(solution.description.length > 250)

  useEffect(() => {
    const solutionNames = solutionsToCompare?.map((s) => {
      return s.name
    })
    if (solutionNames?.includes(solution.name)) {
      setIsChecked(true)
    } else {
      setIsChecked(false)
    }
  }, [solutionsToCompare])

  const trimmedDescription = solution.description.trim()
  const shouldMinify = trimmedDescription.length > 250 && showMore
  const displayDescription = shouldMinify
    ? minifyString(trimmedDescription, 250, 250, true)
    : trimmedDescription

  return (
    <div className='flex flex-col rounded-lg shadow-service-hero'>
      <div className='flex self-end h-[20px]'>
        {solution.is_featured &&
          <p className='rounded-tr-lg rounded-bl-lg bg-ocean-blue px-[8px] py-[2px] text-[10.7px] font-semibold text-white tracking-[1px]'>
            Featured
          </p>
        }
      </div>
      <div className='flex flex-col gap-4 px-4 pb-4'>
        <div className='flex gap-3'>
          <div className='flex max-h-[50px] min-h-[50px] min-w-[50px] max-w-[50px]'>
            <img
              className='max-h-[50px] min-h-[50px] min-w-[50px] max-w-[50px] rounded-md'
              src={solution.logo_url || solutionLogoPlaceholder}
              alt='logo'
              rel='nofollow'
            />
          </div>
          <div className='flex flex-col'>
            <p className='mt-1 text-[14.8px] font-bold'>
              {solution.name}
            </p>
            {solution.ratings_count >= 0 && (
              <SolutionStarRating rating={solution.rating} ratingCount={solution.ratings_count} variant='secondary'/>
            )}
          </div>
        </div>
        <p className='text-[12px] leading-[22px] w-[98%]'>
          {displayDescription}
          {trimmedDescription.length > 250 && (
            <button
              className='ml-1 text-teal'
              onClick={() => setShowMore(!showMore)}
            >
              {showMore ? 'Show more' : 'Show less'}
            </button>
          )}
        </p>
        <div className={`flex justify-between gap-2 ${solution.custom_solution_cta.text ? 'max-md:flex-col' : 'flex-row'}`}>
          <div className='flex flex-row justify-center gap-2'>
            <HybridLink href={`/solutions/${solution.slug}`} >
              <p className='h-[28px] w-[165px] rounded-lg bg-ocean-blue pt-1 text-center text-[12px] font-semibold tracking-[1px] text-white sm:w-[216px]'>
                View Profile &gt;
              </p>
            </HybridLink>
            {solution.custom_solution_cta.text && (
              <HybridLink
                href={solution.custom_solution_cta.url.includes('https') ? solution.custom_solution_cta.url : `https://${solution.custom_solution_cta.url}`}
              >
                <p className='h-[28px] w-[165px] rounded-lg bg-custom-blue pt-1 text-center text-[12px] font-semibold tracking-[1px] text-white sm:w-[216px]'>
                  {solution.custom_solution_cta.text}
                </p>
              </HybridLink>
            )}
          </div>
          <div className='flex justify-end'>
            <SaveForLaterButton
              solutionsToCompare={solutionsToCompare}
              isChecked={isChecked}
              setIsChecked={setIsChecked}
              setSolutionsToCompare={setSolutionsToCompare}
              solution={solution}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default CategorySolutionCard
