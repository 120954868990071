import HeroImageComponent from '@/components/atoms/solutionProfile/HeroImageComponent'
import EmptySection from '@/components/atoms/widgets/EmptySection'
import { ChangeEvent, useRef } from 'react'
import { MdOutlineModeEditOutline } from 'react-icons/md'
import { LiaTrashAltSolid } from 'react-icons/lia'
import { deleteRequest } from '@/utils/axiosRequests'
import { confirmDialogue } from '@/utils/confirmDialogue'
import { useToken } from '@/components/atoms/forms/Form'
import { toast } from 'react-toastify'
import { router } from '@inertiajs/react'
import { defaultToastOptions } from '@/utils/commonToast'
import SuccessMessage from '@/components/atoms/Toast/SuccessMessage'
import ErrorMessage from '@/components/atoms/Toast/ErrorMessage'
import { TIMEOUT_INTERVAL } from '@/utils/constants'
import { Menu } from '@headlessui/react'
import { FiMonitor, FiSmartphone } from 'react-icons/fi'
import { DELETE_MOBILE_HERO_IMAGE, DELETE_DESKTOP_HERO_IMAGE} from '@/utils/constants'

type HeroImageSectionProps = {
  solution: schema.Solution
  current_user?: schema.User
  is_claimed: boolean
  solutionOwner: schema.User
  heroImageURL?: string
  mobileHeroImageURL?: string
  heroPreviewUrl?: string
  mobileHeroPreviewUrl?: string
  handleHeroImageChange: (
    event: ChangeEvent<HTMLInputElement>
  ) => Promise<void>
  handleMobileHeroImageChange: (
    event: ChangeEvent<HTMLInputElement>
  ) => Promise<void>
  setSelectedInput: React.Dispatch<React.SetStateAction<'desktop' | 'mobile'>>
  selectedInput: 'desktop' | 'mobile'
  isMobile: boolean
  isRmcAdmin: boolean
}

const HeroImageSection = ({
  solution,
  is_claimed,
  current_user,
  solutionOwner,
  heroPreviewUrl,
  mobileHeroPreviewUrl,
  heroImageURL,
  mobileHeroImageURL,
  handleHeroImageChange,
  handleMobileHeroImageChange,
  isRmcAdmin,
  setSelectedInput,
  selectedInput,
  isMobile,
}: HeroImageSectionProps) => {
  const heroImage = heroPreviewUrl || heroImageURL
  const mobileHeroImage = mobileHeroPreviewUrl || mobileHeroImageURL

  const token = useToken()

  const heroInputRef = useRef<HTMLInputElement>(null)
  const mobileHeroInputRef = useRef<HTMLInputElement>(null)

  const handleDeleteHeroImage = async (device_delete_endpoint: String) => {
    try {
      if (!confirmDialogue('Are you sure you want to delete this image?'))
        return
      const response = await deleteRequest(
        `/solutions/${solution.id}/${device_delete_endpoint}`,
        token
      )

      const message = response.data.message

      if (message === 'Success') {
        toast(
          <SuccessMessage message={'Hero Image has been deleted'} />,
          defaultToastOptions('teal-tint-2')
        )
        setTimeout(
          () => router.visit(`/solutions/${solution.slug}`),
          TIMEOUT_INTERVAL
        )
      } else if (message === 'Not Found') {
        toast(
          <ErrorMessage message={'Hero Image not Found'} />,
          defaultToastOptions('red-200')
        )
      }
    } catch (error) {
      toast(<ErrorMessage />, defaultToastOptions('red-200'))
    }
  }

  const handleFileChange = async (
    event: ChangeEvent<HTMLInputElement>,
    type: 'desktop' | 'mobile'
  ) => {
    const file = event.target.files?.[0]
    if (!file) return

      if (type === 'desktop') {
        await handleHeroImageChange(event)
      } else {
        await handleMobileHeroImageChange(event)
      }
    
  }

  const handleInputClick = (type: 'desktop' | 'mobile') => {
    if (type === 'desktop') {
      heroInputRef.current?.click()
      setSelectedInput('desktop')
    } else {
      mobileHeroInputRef.current?.click()
      setSelectedInput('mobile')
    }
  }

  return (
    solution.paid_solution &&
    (is_claimed || isRmcAdmin) ? (
      <div className='bg-white px-4 py-10 md:p-10 xl:px-20 xl:py-[20px] xl:self-center w-full max-w-[1350px]'>
        {(current_user && current_user?.id === solutionOwner?.id) ||
        isRmcAdmin ? (
          <div className='relative'>
            <div className='absolute right-0 mt-[10px] mr-[10px] rounded-full bg-white p-2 flex flex-row gap-2'>
              <Menu>
                <Menu.Button>
                  <MdOutlineModeEditOutline className='text-[20px] text-dark-blue cursor-pointer' />
                </Menu.Button>
                <Menu.Items className='absolute mt-2 w-64 right-10 bg-white border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg'>
                  <div className='px-4 py-2 text-gray-500'>
                    Select screen size
                  </div>
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        className={`flex items-center gap-2 block px-4 py-2 w-full text-sm ${active ? 'bg-blue-100' : ''}`}
                        onClick={() => handleInputClick('desktop')}
                      >
                        <FiMonitor className='w-5 h-5 mr-3' />
                        Desktop
                      </button>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        className={`flex items-center gap-2 block px-4 py-2 w-full text-sm ${active ? 'bg-blue-100' : ''}`}
                        onClick={() => handleInputClick('mobile')}
                      >
                        <FiSmartphone className='w-5 h-5 mr-3' />
                        Mobile
                      </button>
                    )}
                  </Menu.Item>
                </Menu.Items>
              </Menu>
              {heroImage && (
                <LiaTrashAltSolid
                  className={`${isMobile ? 'hidden' : 'flex'} text-[20px] text-dark-blue cursor-pointer`}
                  onClick={() =>
                    handleDeleteHeroImage(DELETE_DESKTOP_HERO_IMAGE)
                  }
                />
              )}
              {mobileHeroImage && (
                <LiaTrashAltSolid
                  className={`${isMobile ? 'flex' : 'hidden'} text-[20px] text-dark-blue cursor-pointer`}
                  onClick={() =>
                    handleDeleteHeroImage(DELETE_MOBILE_HERO_IMAGE)
                  }
                />
              )}
              <input
                id='hero-image-input'
                type='file'
                accept='.jpg,.png,.jpeg,.webp'
                onChange={(event) => handleFileChange(event, selectedInput)}
                ref={heroInputRef}
                className='hidden'
              />
              <input
                id='mobile-hero-image-input'
                type='file'
                accept='.jpg,.png,.jpeg,.webp'
                onChange={(event) => handleFileChange(event, selectedInput)}
                ref={mobileHeroInputRef}
                className='hidden'
              />
            </div>

            <div className={`${isMobile ? 'hidden' : 'flex'}`}>
              {heroImage ? (
                <HeroImageComponent image={heroImage} />
              ) : (
                <div className='w-full'>
                  <EmptySection
                    heading='No Hero Image for Desktop Yet'
                    paragraph='Click the pencil icon to add a Desktop Hero Image to this solution.'
                  />
                </div>
              )}
            </div>
            <div className={`${isMobile ? 'flex' : 'hidden'}`}>
              {mobileHeroImage ? (
                <HeroImageComponent image={mobileHeroImage} />
              ) : (
                <EmptySection
                  heading='No Hero Image for Mobile Yet'
                  paragraph='Click the pencil icon to add a Mobile Hero Image to this solution.'
                />
              )}
            </div>
          </div>
        ) : (
          <>
            <div className={`${isMobile ? 'hidden' : 'flex'}`}>
              {heroImageURL && <HeroImageComponent image={heroImage} />}
            </div>
            <div className={`${isMobile ? 'flex' : 'hidden'}`}>
              {mobileHeroImage && <HeroImageComponent image={mobileHeroImage} />}
            </div>
          </>
        )}
      </div>
    ) : (
     
      (isRmcAdmin || solutionOwner) && (
        ((current_user && current_user?.id === solutionOwner?.id) || isRmcAdmin) && (
            <div className='bg-white px-4 py-10 md:p-10 xl:px-20 xl:py-[20px] xl:self-center w-full max-w-[1350px]'>
                <div className='w-full'>
                    <EmptySection
                        heading='No Hero Image for Desktop Yet'
                        paragraph='Upgrade to a premium profile to access Hero Images.'
                        showUpgradeButton={true}
                    />
                </div>
            </div>
        )
    )
    
   
    )
  )
}

export default HeroImageSection
