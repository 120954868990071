import Button from '@/components/atoms/buttons/Button'
import Input from '@/components/atoms/inputs/Input'
import { useState } from 'react'
import { MdOutlineCreate } from 'react-icons/md'
import { FaPlusCircle } from 'react-icons/fa'
import { HiTrash } from 'react-icons/hi'
import placeHolderImage from '../../../..//assets/images/solution-logo-placeholder.svg'
import { putRequest } from '@/utils/axiosRequests'
import { useToken } from '@/components/atoms/forms/Form'
import { router } from '@inertiajs/react'
import { imageValidation } from '@/utils/fileValidations'
import { toast } from 'react-toastify'
import SuccessMessage from '@/components/atoms/Toast/SuccessMessage'
import { defaultToastOptions } from '@/utils/commonToast'
import ErrorMessage from '@/components/atoms/Toast/ErrorMessage'
import { TIMEOUT_INTERVAL } from '@/utils/constants'
import EmptySection from '@/components/atoms/widgets/EmptySection'
import { isObjectEmpty } from '@/utils/helperFunctions'
import CropModal from '../modals/CropModal'
import { useMobileDetect } from '@/utils/detectMobile'

export const Propositions = ({ solutionPropositions, isSolutionOwner, solutionSlug, isRmcAdmin }) => {

  const MAX_LENGTH_TITLE = 25
  const MAX_LENGTH_DESCRIPTION = 550
  const MAX_FILE_SIZE = 10
  const MAX_PROPOSITION_COUNT = 4
  const isMobile = useMobileDetect({ screenSize: 640 })
  const imageWidth = isMobile ? 300 : 600
  const [openModal, setOpenModal] = useState(false)
  const [selectedFile, setSelectedFile] = useState(null)
  const [propositionIndex, setPropositionIndex] = useState(null)
  const [editView, setEditView] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [propositions, setPropositions] = useState(solutionPropositions?.length > 0 ? solutionPropositions : [{title: '', description: '', image: {}}])

  const token = useToken()
  const solutionURL = `/solutions/${solutionSlug}`

  const closeEditView = () => {
    router.visit(solutionURL)
  }

  const showEmptySection = (!editView && solutionPropositions.length === 0 && (isRmcAdmin || isSolutionOwner))

  const validatePropositionText = (proposition) => {
    return !proposition._destroy && (!validateText(proposition.title, MAX_LENGTH_TITLE, 'Title') || !validateText(proposition.description, MAX_LENGTH_DESCRIPTION, 'Description'))
  }

  const buildFormData = () => {
    const formData = new FormData()
    for( let index=0; index < propositions.length; index++ ) {
      const proposition = propositions[index]
      const { title, description, id, _destroy, image } = proposition
      const validateTitleAndDescription = validatePropositionText(proposition)
      if (validateTitleAndDescription) {
        return false
      }
      
      const propAttributes = `solution[propositions_attributes][${index}]`
      formData.append(`${propAttributes}[title]`, title)
      formData.append(`${propAttributes}[description]`, description)
      
      if (id) {
        formData.append(`${propAttributes}[id]`, id)
      }
      if (_destroy) {
        formData.append(`${propAttributes}[_destroy]`, _destroy)
      }
      if (image instanceof File) {
        formData.append(`${propAttributes}[image]`, image)
      }
      else{
        if(!(_destroy) && isObjectEmpty(image)){
          errorToast("Please provide an image")
          return false
        }
      }
    }

    return formData
  }

  const handleSubmit = async () => {
    const formData = buildFormData()
    if(!formData) return
    try{
      setIsLoading(true)
      const response = await putRequest(solutionURL, formData, token)
      if(response.status === 200){
        successToast('Proposition has been saved successfully!')
        setTimeout(() => {
          router.visit(solutionURL)
        }, TIMEOUT_INTERVAL)
      }
    }
    catch(error){
      setIsLoading(true)
      errorToast('Something went wrong, please try again!')
    }
    finally {
      setIsLoading(false)
    }
  }

  const validateText = (text, maxLength, name) => {
    if(!text) {
      errorToast('Please fill out the required fields!')
      return false
    }
    if(text.length > maxLength)  {
      errorToast(`${name} length should not exceed ${maxLength} characters!`)
      return false
    }
    return true
  }

  const validateImage = (image) => {
    const errors = imageValidation(image, MAX_FILE_SIZE)
    if(errors.length > 0){
      errors.forEach((error)=>{
      errorToast(error)})
      return false
    }
    return true
  }

  const errorToast = (message) => {
    toast(
      <ErrorMessage message={message}/>,
      defaultToastOptions('red-200')
    )
  }

  const successToast = (message) => {
    toast(
    <SuccessMessage message={message}/>,
    defaultToastOptions('teal-tint-2')
    )
  }

  const updateProposition = (propositionIndex, newProposition) => {
    setPropositions(
      propositions.map((proposition, index) => {
        if (index === propositionIndex) {
          proposition.title = newProposition.title
          proposition.description = newProposition.description
          proposition.image = newProposition.image
        }
        return proposition
      })
    )
  }

  const addProposition = () => {
    setPropositions([...propositions, {title: '', description: '', image: {}}])
  }

  const removeProposition = (propositionIndex) => {
    setPropositions(
      propositions.map((proposition, index) => {
        if (index === propositionIndex) {
          proposition._destroy = true
          proposition.image = null
        }
        return proposition
      })
    )
    setOpenModal(false)
  }

  const handleFileChange = (file) => {
      setPropositions(
      propositions.map((proposition, index) => {
        if (index == propositionIndex) {
          proposition['image'] = file
          proposition['newImageUrl'] = URL.createObjectURL(file);
        }
        return proposition
      })
    )
    setOpenModal(false)
  }

  const handleOpenModal = (file, index) => {
    setSelectedFile(file)
    setPropositionIndex(index)
    setOpenModal(true)
  }

  const handleImageChange = (file, propositionIndex) => {
    if(!validateImage(file))
    {
      return false
    }
    handleOpenModal(file, propositionIndex)
  }

  const propositionsWithoutDestroyFlag = () => {
    let count = 0
    propositions.forEach((proposition) => {
      !proposition._destroy && count ++ 
    })
    return count
  }

  return (
    <div className='mt-20'>
      {(
        <div className='flex flex-col gap-10'>
          <div className='flex justify-between'>
            <p className='text-xl font-bold mb-5'>Product Information</p>
            {(isSolutionOwner || isRmcAdmin) &&
              (!editView ?
                  <MdOutlineCreate
                    className='bg-light-gray p-2 rounded-full text-[35px] cursor-pointer'
                    onClick={() => setEditView(!editView)}
                  /> : 
                  <div className='flex gap-2'>
                    <Button
                      variant={isLoading ? 'gray' : 'teal'}
                      className={`${isLoading && 'text-gray-400'} flex h-12 w-20 items-center justify-center rounded-[12px] px-4 py-3`}
                      onClick={isLoading ? () => {} : () => handleSubmit()}
                    >
                      {isLoading ? 'Saving...' : 'Save'}
                    </Button>
                    <Button 
                      variant='gray'
                      className='flex h-12 w-20 items-center justify-center rounded-[12px] px-4 py-3'
                      onClick={() => closeEditView()}
                    >
                      Cancel
                    </Button>
                  </div>
              )
            }
          </div>
          {editView && propositions?.map((proposition, index) => {
          return (
            !proposition._destroy && (
              <div className='flex flex-col sm:flex-row gap-20' key={index}>
                <div className='flex flex-col w-full'>
                  <div className='flex justify-between'>
                    <label htmlFor='Title' className='my-3 block text-lg text-black'>
                      Title*
                    </label>
                    <HiTrash onClick={() => removeProposition(index)} className='sm:hidden cursor-pointer text-[40px]'/>
                  </div>
                  <Input 
                    value={proposition.title} 
                    onChange={(e) => {
                      const newProposition = { ...proposition, title: e.target.value }
                      updateProposition(index, newProposition)
                    }}
                    className='w-full'
                    id='Title'
                  />
                  <div className='flex my-5'>
                    <div
                      className={`text-xs font-medium ${proposition.title?.length > 25 ? 'text-danger-red' : 'text-[#8A8DA0]'}`}
                    >
                      Max 25 characters
                    </div>
                    <div
                      className={`ml-auto text-xs font-medium ${proposition.title?.length > 25 ? 'text-danger-red' : 'text-[#8A8DA0]'}`}
                    >
                      {proposition.title?.length}
                    </div>
                  </div>
                  <label htmlFor='description' className='my-3 block text-lg text-black'>
                    Description*
                  </label>
                  <textarea
                    className='mb-0 h-[383px] w-full rounded-xl border border-dark-gray px-5 py-4 text-black focus:border-dark-gray focus:outline-none focus:ring-0 md:h-[221px] lg:h-[194px]'
                    id='description'
                    value={proposition.description}
                    onChange={(e) => {
                      const newProposition = { ...proposition, description: e.target.value }
                      updateProposition(index, newProposition)
                    }}
                  />
                  <div className='flex my-5'>
                    <div
                      className={`text-xs font-medium ${proposition.description?.length > 550 ? 'text-danger-red' : 'text-[#8A8DA0]'}`}
                    >
                      Max 550 characters
                    </div>
                    <div
                      className={`ml-auto text-xs font-medium ${proposition.description?.length > 550 ? 'text-danger-red' : 'text-[#8A8DA0]'}`}
                    >
                      {proposition.description?.length}
                    </div>
                  </div>
                </div>
                <div className='flex flex-col w-full'>
                  <div className='flex items-center justify-between mb-2'>
                    <p>Image*</p>
                    <label htmlFor={`change-image-${index}`}>
                      <div className='bg-dark-blue cursor-pointer text-white px-2 py-[2px] rounded-xl'>
                        {proposition.image.url ? 'Change' : 'Upload'}
                      </div>
                      <input
                        id={`change-image-${index}`}
                        type='file'
                        accept='.jpg,.png,.jpeg'
                        onChange={(e) => { handleImageChange(e.target.files[0], index) }}
                        className='hidden'
                      />
                      <CropModal
                        openModal={openModal}
                        setOpenModal={setOpenModal}
                        src={selectedFile}
                        handleFileChange={handleFileChange}
                        maxImageSize={10 * 1024 * 1024}
                        imageWidth={imageWidth}
                      />
                    </label>
                  </div>
                  <div className={`bg-gray-200 w-full rounded-xl justify-center items-center ${proposition.image.url || proposition.newImageUrl ? 'p-0' : 'p-8'}`}>
                    <img 
                      src={proposition.newImageUrl || proposition.image.url || placeHolderImage} 
                      alt='proposition-image' 
                      className='w-full h-[300px] rounded-xl'
                    />
                  </div>
                  <div className='flex flex-col my-3'>
                    <p className='text-xs text-dark-gray my-3'>Maximum File Size: 10Mb</p>
                    <p className='text-xs text-dark-gray'>Ideal Aspect Ration: 16:9</p>
                  </div>
                </div>
                <HiTrash onClick={() => removeProposition(index)} className='hidden sm:block cursor-pointer text-[100px]'/>
              </div>
            )
          )})}
        </div>
      )}
      { editView && propositionsWithoutDestroyFlag() < MAX_PROPOSITION_COUNT &&
        <div className='flex justify-center cursor-pointer text-[50px] my-3' onClick={() => addProposition()}>
          <FaPlusCircle />
        </div>
      }
      { showEmptySection ? 
      <EmptySection heading='No Product Information Yet' 
                    paragraph='Click the pencil icon to add a proposition to your profile.'
      /> : (
        !editView && <div className='flex flex-col gap-20'>
          {propositions.map((proposition, index) => (
            <div key={index} className={`flex flex-col-reverse gap-10 sm:items-center ${index % 2 === 0 ? 'sm:flex-row-reverse' : 'sm:flex-row'}`}>
              <div className='flex flex-col sm:w-[50%]'>
                <p className='text-[24px] font-bold'>{proposition?.title}</p>
                <p className='text-[14px]'>{proposition?.description}</p>
              </div>
              { proposition?.image?.url &&
                <img src={proposition?.image?.url} alt='' className='w-full sm:w-[50%] max-h-[300px] rounded-xl' />
              }
            </div>
          ))}
        </div>
      )}
    </div>
  )
}
