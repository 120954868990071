import { MultiSelect } from 'react-multi-select-component'

import './styles.css'

type IntegrationsDropdownProps = {
  integrations: string[]
  selectedIntegrations: string[]
  setSelectedIntegrations: React.Dispatch<React.SetStateAction<string[]>>
}

const IntegrationsDropdown = ({ integrations, selectedIntegrations, setSelectedIntegrations }: IntegrationsDropdownProps) => {

  const integrationOptions = integrations.map((integration) => ({
    label: integration,
    value: integration,
  }))

  return (
    <div className='relative cursor-pointer'>
      {selectedIntegrations.length > 0 &&
        <span className='absolute top-[-3px] -right-1 flex size-3 rounded-full bg-ocean-blue z-10' />
      }
      <MultiSelect
        options={integrationOptions}
        value={selectedIntegrations}
        onChange={setSelectedIntegrations}
        labelledBy="Integrations"
        valueRenderer={() => "Integrations"}
        overrideStrings={{
          selectSomeItems: "Integrations"
        }}
        className='text-[12px]'
        ClearSelectedIcon={null}
        hasSelectAll={false}
      />
    </div>
  )
}

export default IntegrationsDropdown
