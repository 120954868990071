import { addSolutionUrl } from "@/utils/constants"

type ClaimButtonProps = {
  handleSubmitClaim: () => void;
  solution: schema.Solution;
}

const ClaimSolutionButton = ({ handleSubmitClaim, solution}: ClaimButtonProps) => {
  return (
    <p className="text-xs sm:text-sm leading-[20px] text-dark-gray flex flex-col items-end">
      Do you work for {solution.name}?{' '}
      <span
        className='cursor-pointer underline text-dark-blue font-bold'
        onClick={() => window.location.href = addSolutionUrl }
      >
      Verify this solution
      </span>
    </p>
  )
}

export default ClaimSolutionButton
