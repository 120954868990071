import { useRef } from 'react'

import { BuyerGuideNavyPanel } from './BuyerGuideNavyPanel'

import { useMobileDetect } from '@/utils/detectMobile'

export const BuyerGuideContent = () => {

  const paraClasses = 'text-[15px] text-light-grey font-medium'
  const headingClasses = 'text-2xl font-semibold text-light-grey'

  const isMobile = useMobileDetect({ screenSize: 1025 })
  const navyPanelQuestions = [
    'What Is an ATS?',
    'ATS Considerations for Different Sizes of Businesses',
    'Key Features to Look for in an ATS',
    'Evaluation and Selection Process',
    'Implementation and Adoption',
    'Trends and Considerations'
  ]
  const sectionRefs = navyPanelQuestions.map(() => useRef(null))

  return (
    <div className='flex gap-16 pt-20'>
      {!isMobile && (
        <BuyerGuideNavyPanel sectionRefs={sectionRefs} navyPanelQuestions={navyPanelQuestions} />
      )
      }
      <div className={`flex w-[666px] flex-col gap-7 ${isMobile ? 'p-5' : ''}`}>
        <div ref={sectionRefs[0]} className='flex flex-col gap-4'>
          <h2 className={headingClasses}>What Is an ATS?</h2>
          <p className={paraClasses}>
            An Applicant Tracking System (ATS) is a software solution that automates and streamlines the recruitment process for businesses.
            It assists organizations in managing job postings, collecting and screening resumes, scheduling interviews, and tracking candidates
            throughout the hiring journey. <br /> <br />
            By automating manual tasks—like filtering applications based on specific criteria and handling candidate communications—an ATS saves time
            and reduces costs. Advanced systems offer features such as resume parsing, interview scheduling automation, and integration with other HR tools,
            enhancing the efficiency and effectiveness of your recruitment efforts.
          </p>
        </div>
        <div ref={sectionRefs[1]} className='flex flex-col gap-2'>
          <h2 className={headingClasses}>ATS Considerations for Different Sizes of Businesses</h2>
          <h3 className='text-base font-semibold text-[#11447B]'>Small Businesses</h3>
          <p className={paraClasses}>
            For small businesses with limited resources, budget-friendly ATS options are crucial.
            Look for systems that offer core functionalities like automated job postings, basic resume screening, and easy integration with existing
            tools like email and calendars. User-friendliness and responsive customer support are essential to get your team up and running
            quickly without extensive training.
          </p>
          <h3 className='text-base font-semibold text-[#11447B]'>Startups</h3>
          <p className={paraClasses}>
            Startups need an ATS that can scale with rapid growth and adapt to changing hiring needs. Focus on systems that offer scalability, flexibility,
            and features that expedite the hiring process, such as efficient resume screening and automated interview scheduling.
            Integration with employer branding tools can also help attract top talent in competitive markets.
          </p>
          <h3 className='text-base font-semibold text-[#11447B]'>Medium-Sized Businesses</h3>
          <p className={paraClasses}>
            Medium-sized businesses should seek a balance between core functionalities and advanced features like skills assessments and onboarding automation.
            An ATS that offers robust reporting and analytics can provide insights to refine your recruitment strategy. Ensure the system integrates seamlessly
            with your existing HR and productivity tools to streamline workflows and avoid data silos.
          </p>
          <h3 className='text-base font-semibold text-[#11447B]'>Enterprise Companies</h3>
          <p className={paraClasses}>
            Enterprise companies require an ATS that can handle complex hiring needs across multiple locations and departments. Look for enterprise-grade
            features like customizable workflows, global capabilities (supporting multiple languages and compliance with regional regulations), and advanced
            analytics. Integration with existing HRIS and other systems is crucial for data consistency and streamlined processes.
          </p>
        </div>
        <div ref={sectionRefs[2]} className='flex flex-col gap-4'>
          <h2 className={headingClasses}>Key Features to Look for in an ATS</h2>
          <ul className='list-disc pl-5 font-bold text-[#11447B]'>
            <li>Automation of Recruitment Tasks: <p className='font-light text-[#2E343A]'>Automate repetitive tasks such as email communications, interview scheduling, and candidate follow-ups to save time and reduce manual effort.</p></li>
            <li>Advanced Resume Parsing and Screening: <p className='font-light text-[#2E343A]'>Efficiently parse resumes and identify qualified candidates based on keywords, skills, and experience to streamline the screening process.</p></li>
            <li>Candidate Relationship Management (CRM): <p className='font-light text-[#2E343A]'>Build and maintain a talent pipeline by tracking interactions with candidates and nurturing relationships for future hiring needs.</p></li>
            <li>Data and Analytics: <p className='font-light text-[#2E343A]'>Utilize reporting tools to gain insights into your recruitment process, including metrics like time-to-hire, source of hire, and diversity statistics, to make data-driven decisions.</p></li>
            <li>Integration Capabilities: <p className='font-light text-[#2E343A]'>Ensure the ATS can integrate with your existing HR tools, such as HRIS, payroll systems, and calendar applications, for a seamless workflow.</p></li>
            <li>User-Friendly Interface: <p className='font-light text-[#2E343A]'>A straightforward and intuitive interface increases user adoption and minimizes the learning curve for your team.</p></li>
            <li>Security and Compliance: <p className='font-light text-[#2E343A]'>Protect sensitive candidate information with robust security features and ensure compliance with data privacy regulations.</p></li>
          </ul>
        </div>
        <div ref={sectionRefs[3]} className='flex flex-col gap-4'>
          <h2 className={headingClasses}>Evaluation and Selection Process</h2>
          <p className={paraClasses}>
            Selecting the right Applicant Tracking System (ATS) is a critical decision that can significantly impact your hiring efficiency and outcomes.
            Begin by identifying key stakeholders—such as HR personnel, recruiters, hiring managers, and IT staff—and involve them in the evaluation process
            to ensure the system meets all organizational needs. <br /> <br />
            Assess your current hiring challenges to pinpoint specific pain points an ATS should address. Research and compare different ATS options by reading
            reviews, leveraging professional networks, and following your company's procurement procedures. Scheduling demos and utilizing free trials can
            provide hands-on experience, helping you make an informed choice that aligns with your recruitment goals.
          </p>
        </div>
        <div ref={sectionRefs[4]} className='flex flex-col gap-4'>
          <h2 className={headingClasses}>Implementation and Adoption</h2>
          <p className={paraClasses}>
            Effective implementation of your chosen ATS is essential to maximize its benefits. Develop a clear plan that includes process mapping to
            integrate the new system smoothly with existing workflows. <br /> <br />
            Invest in comprehensive training for all users to encourage adoption and minimize resistance to change. Establish "software champions" within
            your team to promote the system's usage and provide peer support. Measure success by tracking key metrics like time-to-hire, quality of hire,
            and user satisfaction. Regular reviews and adjustments will help you realize a strong return on investment and continuously improve your
            hiring process.
          </p>
        </div>
        <div ref={sectionRefs[5]} className='flex flex-col gap-4'>
          <h2 className={headingClasses}>Trends and Considerations</h2>
          <p className={paraClasses}>
            Staying updated on the latest trends in recruitment technology can give your organization a competitive edge. Modern ATS platforms are
            increasingly incorporating Artificial Intelligence (AI) and automation to enhance functionalities like resume screening, candidate matching,
            and reducing unconscious bias. <br /> <br />
            Mobile optimization is becoming essential as more candidates use mobile devices for job searches and applications. Additionally, focusing
            on diversity, equity, inclusion, and belonging (DEIB) within your ATS can help attract a broader talent pool and foster a more inclusive
            workplace. Prioritizing data privacy and security is also crucial to protect sensitive candidate information and comply with evolving regulations.
          </p>
        </div>
      </div>
    </div>
  )
}