import Button from '@/components/atoms/buttons/Button'
import { useState } from 'react'
import { MdOutlineEdit as EditIcon } from 'react-icons/md'
import SolutionForm from '../forms/SolutionForm'
import { isValidURL } from '@/utils/helperFunctions'
import PillWithIcon from '@/components/atoms/widgets/PillWithIcon'
import { ReactComponent as GlobeIcon } from 'app/assets/images/globe-icon.svg'
import { FaLinkedin as LinkedinIcon } from "react-icons/fa"
import { FaInstagram as InstagramIcon  } from "react-icons/fa6"
import { FaXTwitter as TwitterIcon } from "react-icons/fa6"
import { FaThreads as ThreadsIcon } from "react-icons/fa6"
import EmptySection from '@/components/atoms/widgets/EmptySection'
import minifyString from '@/utils/minifyString'

type SolutionDetailsProps = {
  solution: schema.Solution
  currentUser: schema.User 
  socialLinks: schema.SocialLink[]
  isClaimed: boolean
  solutionOwner: schema.User
  isSolutionClaimed: boolean
  aboutMedia: {
    media_url: string
    media_type: string
    file_name: string
  }
  isRmcAdmin: boolean
}

const SolutionDetails = ({
  solution,
  currentUser,
  socialLinks,
  isClaimed,
  solutionOwner,
  isSolutionClaimed,
  aboutMedia,
  isRmcAdmin
}: SolutionDetailsProps ) => {
  const [showSolutionForm, setShowSolutionForm] = useState(false)
  const [showMore, setShowMore] = useState(solution.description.length > 730)

  const showMediaDetails = solution.paid_solution && aboutMedia
  const trimmedDescription = solution.description.trim()
  const shouldMinify = trimmedDescription.length > 730 && showMore
  const displayDescription = shouldMinify ? minifyString(trimmedDescription, 730, 730, true) : trimmedDescription

  const websiteDomain = isValidURL(solution.website)
    ? new URL(solution.website)?.hostname
    : null

  const handleEditClick = () => {
    setShowSolutionForm(true)
  }

  const iconMap = {
    LinkedIn: LinkedinIcon,
    Instagram: InstagramIcon,
    Twitter: TwitterIcon,
    Threads: ThreadsIcon,
  }

  return (
    <div id='about'>
      {!showSolutionForm && (
        <div className='flex align-baseline'>
          {currentUser &&
            (currentUser.id === solutionOwner?.id || isRmcAdmin) && (
              <>
                {!solution.description && (
                  <h4 className='font-bold uppercase text-[20px] mb-5 lg-mt-0 lg:text-[24px] text-black'>
                    {solution.title || 'Details'}
                  </h4>
                )}
                <div className='ml-auto'>
                  <Button
                    size='md'
                    variant='tertiary'
                    className='flex h-9 w-9 items-center justify-center !rounded-full p-0 cursor-pointer mb-5'
                    onClick={handleEditClick}
                    style={{ padding: 0 }}
                  >
                    <EditIcon size={22} className='cursor-pointer' />
                  </Button>
                </div>
              </>
            )}
        </div>
      )}

      {showSolutionForm && (
        <SolutionForm
          solution={solution}
          key={solution.id}
          social_links={socialLinks}
          about_media={aboutMedia}
          isRmcAdmin={isRmcAdmin}
        />
      )}
      {!showSolutionForm && solution.description && (
        <div className='flex flex-col-reverse lg:flex-row justify-between'>
          <div
            className={`${showMediaDetails && 'lg:w-1/2'} flex flex-col w-full gap-6 sm:py-0 md:gap-3 justify-center`}
          >
            <div className='flex flex-col gap-3 lg:pr-5'>
              <div className='flex items-center justify-between break-all'>
                <h4 className='font-bold uppercase text-[20px] mt-5 lg-mt-0 lg:text-[24px] text-black'>
                  {solution.title || 'Details'}
                </h4>
              </div>
              <div className='text-sm text-black'>
                {displayDescription.endsWith('.') ? displayDescription : `${displayDescription}.`}
                {trimmedDescription.length > 730 && (
                  <button className={'text-teal'} onClick={() => setShowMore(!showMore)}>
                    {showMore ? 'Show more' : 'Show less'}
                  </button>
                )}
              </div>
              <div className='justify-content-start flex gap-2'>
                {websiteDomain && (
                  <PillWithIcon
                    className={'hidden md:flex'}
                    Icon={GlobeIcon}
                    TextOrNode={
                      <a
                        href={solution?.website}
                        className='hover:underline'
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        {websiteDomain}
                      </a>
                    }
                  />
                )}
                {socialLinks.map((link) =>
                  link.url.trim().length !== 0 && isValidURL(link.url) && (
                    <PillWithIcon
                      className={''}
                      Icon={iconMap[link.name]}
                      link={link.url}
                    />
                  )
                )}
              </div>
            </div>
          </div>
          {showMediaDetails && (
            <div className={'w-full lg:w-1/2 flex flex-col items-center justify-center lg:p-5'}>
              {aboutMedia.media_type?.includes('image') ? (
                <img
                  src={aboutMedia.media_url}
                  alt={aboutMedia.file_name}
                  className='max-h-[350px] sm:max-h-[530px] lg:max-h-[320px] w-full rounded-lg'
                />
              ) : (
                <video
                  src={aboutMedia.media_url}
                  controls
                  className='rounded-lg'
                />
              )}
            </div>
          )}
        </div>
      )}
      {(currentUser ? currentUser?.id === solutionOwner?.id : false || isRmcAdmin) && !solution.description && !showSolutionForm && (
        <EmptySection
          heading='No About Section Yet'
          paragraph='Click the pencil icon to add an about section to your profile.'
        />
      )}
    </div>
  )
}

export default SolutionDetails
