import { FiStar } from "react-icons/fi"

import { handleStarClick } from "@/utils/helperFunctions"

export const SaveForLaterButton = ({
  solutionsToCompare,
  isChecked,
  setIsChecked,
  setSolutionsToCompare,
  solution
}) => {
  return (
    <div
      className='flex cursor-pointer items-center gap-1'
      onClick={() => handleStarClick(solutionsToCompare, isChecked, setIsChecked, setSolutionsToCompare, solution)}
    >
      <FiStar
        className={`${isChecked ? 'fill-pink-tint-3 text-pink-tint-3' : 'text-black'}`}
      />
      <p className='text-sm font-semibold text-outer-space'>
        {isChecked ? 'Saved for Later' : 'Save for Later'}
      </p>
    </div>
  )
}
