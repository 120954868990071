import { useEffect, useState } from 'react'

import { useToken } from '@/components/atoms/forms/Form'
import { getAllParentChildCategories } from '@/utils/axiosRequests'

export const SolutionCategoryTag = ({ solution, setCategoryNames }) => {
  const token = useToken()
  const [funnelCategories, setFunnelCategories] = useState([])
  const [selectedCategories, setSelectedCategories] = useState(solution.categories_names || [])

  useEffect(() => {
    const fetchCategories = async () => {
      const response = await getAllParentChildCategories(token)
      setFunnelCategories(response.data)
    }
    fetchCategories()
  }, [])

  const handleCheckboxChange = (name) => {
    setSelectedCategories((prevSelected) => {
      const newCategories = [...prevSelected]
      if (newCategories.includes(name)) {
        const updatedCategories = newCategories.filter((item) => item !== name)
        setCategoryNames(updatedCategories)
        return updatedCategories
      } else {
        const updatedCategories = [...newCategories, name]
        setCategoryNames(updatedCategories)
        return updatedCategories
      }
    })
  }

  return (
    <div className='mt-5 '>
      <p className='mb-5 text-lg font-semibold'>Select all the relevant categories</p>
      <div className='flex flex-col sm:max-h-[930px] sm:flex-wrap'>
        {Object.keys(funnelCategories).map((key) => (
          <div
            key={key}
            className={`mb-5 mt-3`}
          >
            <p className='mb-2 font-bold text-light-navy'>{key}</p>
            {funnelCategories[key].map((obj) => (
              <div key={obj.name} className='item-center m-3 flex justify-start gap-2'>
                <input
                  type='checkbox'
                  name={obj.name}
                  className='self-center rounded-sm checked:bg-cyan'
                  checked={selectedCategories.includes(obj.name)}
                  onChange={() => handleCheckboxChange(obj.name)}
                />
                <label htmlFor={obj.name}>{obj.name}</label>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  )

}
