import React, { useEffect, useRef, useState } from 'react'

import clsx from 'clsx'

import { ReactComponent as Chevron } from '../../../../assets/images/chevron-clear.svg'
import trophyIcon from '../../../../assets/images/trophy.svg'

type AwardsSectionProps = {
  rmcAwards: schema.RmcAward[]
}

const AwardsSection = ({ rmcAwards }: AwardsSectionProps) => {
  const sliderRef = useRef(null)
  const [isLeftScrollable, setIsLeftScrollable] = useState(true)
  const [isRightScrollable, setIsRightScrollable] = useState(true)

  const scroll = (scrollOffset) => {
    if (sliderRef.current) {
      sliderRef.current.scrollLeft += scrollOffset
    }
  }

  useEffect(() => {
    const handleScroll = () => {
      if (sliderRef.current) {
        const { scrollLeft, scrollWidth, clientWidth } = sliderRef.current
        const maxScroll = scrollWidth - clientWidth

        setIsLeftScrollable(scrollLeft > 0)
        setIsRightScrollable(scrollLeft < maxScroll)
      }
    }

    if (sliderRef.current) {
      sliderRef.current.addEventListener('scroll', handleScroll)
      handleScroll()
    }

    return () => {
      if (sliderRef.current) {
        sliderRef.current.removeEventListener('scroll', handleScroll)
      }
    }
  }, [])

  return (
    <div className='mt-10'>
      <div className='flex justify-between'>
        <h2 className='mb-5 text-lg font-bold leading-[30px] text-black md:text-xl md:leading-[35px]'>
          RMC Awards
        </h2>
        <div className='hidden lg:flex lg:gap-2'>
          <button
            onClick={() => scroll(-550)}
            className={clsx(
              'flex size-11 items-center justify-center rounded-full bg-light-gray disabled:cursor-not-allowed disabled:opacity-50'
            )}
          >
            <Chevron
              className={clsx(
                'rotate-90',
                isLeftScrollable ? '!stroke-black' : 'stroke-[#C4C6CF]'
              )}
            />
          </button>
          <button
            onClick={() => scroll(550)}
            className={clsx(
              'flex size-11 items-center justify-center rounded-full bg-light-gray disabled:cursor-not-allowed disabled:opacity-50'
            )}
          >
            <Chevron
              className={clsx(
                '-rotate-90',
                isRightScrollable ? '!stroke-black' : 'stroke-[#C4C6CF]'
              )}
            />
          </button>
        </div>
      </div>
      <div
        className='flex flex-col gap-3 rounded-xl p-4 sm:flex-row sm:overflow-auto sm:scroll-smooth sm:scrollbar-none'
        ref={sliderRef}
      >
        {rmcAwards.map((award) => (
          <div
            className='flex w-full max-w-[550px] flex-col gap-3 rounded-xl px-4 pb-5 pt-8 shadow-card sm:min-w-[550px] md:flex-row md:gap-5 md:p-8'
            key={`rmc-award-${award.id}`}
          >
            <div className='flex self-center'>
              <div className='h-[200px] w-[178px] sm:size-24 md:size-[120px]'>
                <img
                  alt='award'
                  src={award.image_url}
                  className='mb-5 size-full object-cover md:mb-[9px]'
                />
              </div>
            </div>
            <div className='gap-[12px] md:flex md:flex-col'>
              <div className='mb-3 flex w-full items-center justify-center gap-2 rounded-xl bg-gray-100 px-[12px] py-[2px] sm:w-fit'>
                <img src={trophyIcon} alt='target' className='size-6' />
                <p className='text-sm font-bold uppercase'>{award.title}</p>
              </div>
              <p className='text-sm font-[500] text-black'>
                {award.description}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default AwardsSection
