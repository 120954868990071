import React, { useState } from 'react'

import Layout from '@components/organisms/layouts/Layout'
import { useForm, usePage, router } from '@inertiajs/react'
import axios from 'axios'
import clsx from 'clsx'

import PressReleaseModal from '../../components/molecules/accountManagement/PressReleaseModal'
import MarketplaceRow from '../../components/organisms/AccountManagement/MarketplaceRow'

import Button from '@/components/atoms/buttons/Button'
import Form, { useToken } from '@/components/atoms/forms/Form'
import Input from '@/components/atoms/inputs/Input'
import Modal from '@/components/atoms/modals/Modal'
import Meta from '@/components/atoms/widgets/Meta'
import PageTitle from '@/components/atoms/widgets/PageTitle'
import BookACallCta from '@/components/molecules/accountManagement/BookACallCta'
import CreateReferralModal from '@/components/molecules/accountManagement/CreateReferralModal'
import DropdownMenu from '@/components/molecules/accountManagement/DropDownMenu'
import ProfilePageSolutionCard from '@/components/molecules/accountManagement/ProfilePageSolutionCard'
import useCurrentUser from '@/hooks/useCurrentUser'
import { useMobileDetect } from '@/utils/detectMobile'

type AccountManagementProps = {
  form_path: string;
  user: schema.User;
  solutions: schema.Solution[];
};

const AccountManagement = (props: AccountManagementProps) => {
  const currentUser = useCurrentUser()

  const { flash, editForm } = usePage().props
  const { formPath, user, solutions } = props
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [toggleEditPassword, setToggleEditPassword] = useState(false)
  const [newPassword, setNewPassword] = useState('')
  const [currentPassword, setCurrentPassword] = useState('')
  const token = useToken()
  const [toggleEdit, setToggleEdit] = useState((currentUser?.role && currentUser?.role?.name === 'customer') ? true : editForm)
  const [isSaveActive, setIsSaveActive] = useState(false)
  const [passwordError, setPasswordError] = useState('')
  const [showPressReleaseModal, setShowPressReleaseModal] = useState(false)
  const [showCreateReferralModal, setShowCreateReferralModal] = useState(false)
  const isDesktop = useMobileDetect({ screenSize: 1024 })

  const { data, setData, put, errors, transform, reset } = useForm({
    first_name: user?.first_name,
    last_name: user?.last_name,
    company: user?.company,
    email: user?.email,
  })

  const isFormValid = () => {
    return data.first_name && data.last_name && data.email && data.company
  }

  const isPasswordFormValid = () => {
    return currentPassword.trim() && newPassword.trim()
  }

  transform((data) => {
    return {
      user: data,
      authenticity_token: token,
    }
  })

  async function submit(e) {
    e.preventDefault()
    put(formPath)
  }

  async function handleUpdatePassword(e) {
    e.preventDefault()
    const response = await axios.put(
      `/users/${user.id}`,
      {
        user: {
          current_password: currentPassword,
          password: newPassword,
        },
      },
      {
        headers: {
          'X-CSRF-Token': token,
        },
      }
    )
    if (response.data.status == 'success') {
      setNewPassword('')
      setCurrentPassword('')
      setTimeout(() => {
        window.location.href = '/login'
      }, 2000)
    } else {
      setPasswordError(response.data.status)
    }
  }

  const handleDeleteAccount = async () => {
    const response = await axios.delete(`/users/${user.id}`, {
      headers: {
        'X-CSRF-Token': token,
      },
    })
    if ([204, 200].includes(response.status)) {
      router.visit('/login')
    }
  }

  return (
    <Layout>
      <Meta
        title='Manage Your RecruitmentMarketing.com Marketplace Account - Connect
          with Recruitment Marketing SaaS and Service Vendors'
        description='Manage your RecruitmentMarketing.com Marketplace account and connect with recruitment marketing SaaS and service vendors. Update your profile, manage your subscriptions, and collaborate with vendors.'
      />
      <PageTitle title='My Profile | Recruitment Marketing' />
      {Boolean(flash?.notice) && (
        <div
          className='relative mb-4 rounded border border-green-400 bg-teal-tint-2 px-4 py-3 text-green-700'
          role='alert'
        >
          {flash.notice}
        </div>
      )}
      <div className='flex w-full flex-1 flex-col md:w-[700px] lg:w-[900px] xl:w-[1100px]'>
        <div className='flex justify-between'>
          <div className='mb-8 flex w-full flex-col justify-between text-4xl font-bold text-black md:gap-2 lg:w-1/2 lg:text-6xl'>
            <p className='mb-5'>
              {`Hello, ${user.first_name} ${user.last_name}`}
            </p>
            <BookACallCta />
          </div>
          {!isDesktop ? (
            <div className='flex flex-col gap-2'>
            <Button
              variant={'white'}
              className={'relative flex h-[44px] w-[246px] items-center justify-center !rounded-[12px] text-md'}
              style={{ border: "1px solid black" }}
              onClick={() => setShowPressReleaseModal(true)}
            >
              Share a Press Release
            </Button>
            {showPressReleaseModal && (
              <PressReleaseModal onClose={() => setShowPressReleaseModal(false)} />
            )}
            <Button
              variant={'white'}
              className={'relative flex h-[44px] w-[246px] items-center justify-center !rounded-[12px] text-md'}
              style={{ border: "1px solid black" }}
              onClick={() => setShowCreateReferralModal(true)}
            >
              Refer a Vendor
            </Button>
            {showCreateReferralModal && (
              <CreateReferralModal onClose={() => setShowCreateReferralModal(false)} />
            )}
            </div>
          ) : (
            <div>
              <DropdownMenu />
            </div>
          )
          }
        </div>

        <div className='flex'>
          { currentUser?.role?.name !== 'customer' &&
            <p
            className={clsx({
              'pr-5 hover:cursor-pointer': toggleEdit,
              'mr-5 hover:cursor-pointer font-semibold border-b-2 pb-2 border-[#5AEFD6] z-10': !toggleEdit
            })}
            onClick={() => setToggleEdit(false)}
            >
              My Solutions
            </p>
          }
          <p
            className={clsx({
              'hover:cursor-pointer': true,
              'font-semibold border-b-2 pb-2 border-[#5AEFD6] z-10': toggleEdit
            })}
            onClick={() => setToggleEdit(true)}
          >
            Account Details
          </p>
        </div>

        <div className='relative'>
          <div className='absolute bottom-[0.2px] mt-0 h-[2px] w-full bg-[#D9D9D9]' />
        </div>

        {/* user information container */}
        {toggleEdit ? (
          toggleEditPassword ? (
            // EDIT PASSWORD FORM
            <div className='flex flex-1 flex-col justify-between pt-8 md:pt-10 lg:justify-start'>
              {' '}
              <div>
                <Form onSubmit={(e)=>{handleUpdatePassword(e)}}>
                  <Input
                    isPassword={true}
                    label='Old password'
                    //   labelHtmlFor='password'
                    id='password'
                    type='password'
                    name='password'
                    value={currentPassword}
                    onChange={(e) => {
                      setCurrentPassword(e.target.value)
                      setIsSaveActive(true)
                    }}
                    containerClass={`${
                      passwordError ? 'mb-2' : 'mb-7'
                    }`}
                    
                  />
                  {passwordError && (
                    <p className='mb-5 text-xs text-danger-red'>
                      {passwordError}
                    </p>
                  )}
                  <Input
                    isPassword={true}
                    label='New password'
                    labelHtmlFor='new_password'
                    id='new_password'
                    type='password'
                    name='new_password'
                    value={newPassword}
                    onChange={(e) => {
                      setNewPassword(e.target.value)
                      setIsSaveActive(true)
                    }}
                    containerClass='mb-7'
                    // errors={''}
                  />
                  <div className='mt-5 flex flex-col items-center gap-4 sm:mt-20 md:flex-row-reverse'>
                    <Button
                      type='submit'
                      size='md'
                      variant='primary'
                      className='w-full md:w-[171px]'
                      disabled={!isPasswordFormValid() || !isSaveActive}
                    >
                      Save Changes
                    </Button>
                    <Button
                      type='submit'
                      size='md'
                      variant='secondary'
                      className='w-full md:w-[171px]'
                      onClick={() => {
                        setToggleEditPassword(false)
                        setIsSaveActive(false)
                      }}
                    >
                      Cancel
                    </Button>
                    {/* TODO: add delete account handler */}
                  </div>
                </Form>
              </div>
            </div>
          ) : (
            // EDIT ACCOUNT FORM
            <div className='flex flex-1 flex-col pt-8 md:pt-10'>
              <Form onSubmit={submit}>
                <div className='flex flex-col md:flex-1 md:justify-between lg:justify-start'>
                  <div>
                    <div className='md:flex md:flex-row md:gap-4'>
                      <Input
                        label='First name'
                        labelHtmlFor='first_name'
                        id='first_name'
                        type='text'
                        name='first_name'
                        value={data.first_name}
                        onChange={(e) => {
                          setData('first_name', e.target.value)
                          setIsSaveActive(true)
                        }}
                        containerClass='mb-7 w-full sm:w-1/2'
                        errors={errors.first_name}
                      />
                      <Input
                        label='Last name'
                        labelHtmlFor='last_name'
                        id='last_name'
                        type='text'
                        name='last_name'
                        value={data.last_name}
                        onChange={(e) => {
                          setData('last_name', e.target.value)
                          setIsSaveActive(true)
                        }}
                        containerClass='mb-7 w-full sm:w-1/2'
                        errors={errors.last_name}
                      />
                    </div>
                    <div className='md:flex md:flex-row md:gap-4'>
                      <Input
                        label='Company'
                        labelHtmlFor='company'
                        id='company'
                        type='text'
                        name='company'
                        value={data.company}
                        onChange={(e) => {
                          setData('company', e.target.value)
                          setIsSaveActive(true)
                        }}
                        containerClass='mb-7 w-full sm:w-1/2'
                        errors={errors.company}
                      />
                      <Input
                        label='Email address'
                        labelHtmlFor='email'
                        id='email'
                        type='text'
                        name='email'
                        value={data.email}
                        onChange={(e) => {
                          setData('email', e.target.value)
                          setIsSaveActive(true)
                        }}
                        containerClass='mb-7 w-full sm:w-1/2'
                        errors={errors.email}
                        disabled={true}
                      />
                    </div>
                    <div className='mb-8 flex justify-end'>
                      <p
                        className='text-black-blue inline-block cursor-pointer text-right'
                        onClick={() => setToggleEditPassword(true)}
                      >
                        Change Password
                      </p>
                    </div>
                  </div>
                  <div className='flex flex-col items-center gap-4 md:flex-row-reverse'>
                    <Button
                      type='submit'
                      size='md'
                      variant='primary'
                      disabled={!isFormValid() || !isSaveActive}
                      className='w-full md:w-[171px] lg:w-[171px]'
                    >
                      Save Changes
                    </Button>
                    { currentUser?.role?.name !== 'customer' &&
                    <Button
                      type='button'
                      size='md'
                      variant='secondary'
                      className='w-full md:w-[171px] lg:w-[171px]'
                      onClick={() => {
                        setToggleEdit(false)
                        setIsSaveActive(false)
                      }}
                    >
                      Cancel
                    </Button>}
                    {/* TODO: add delete account handler */}
                  </div>
                </div>
                <p
                  className='mt-5 cursor-pointer text-center text-danger-red'
                  onClick={() => setOpenDeleteModal(true)}
                >
                  Delete Account
                </p>
              </Form>
              <Modal
                isOpen={openDeleteModal}
                onClose={() => setOpenDeleteModal(false)}
                closeStyle='top-5 right-4'
                className='mx-3 rounded-xl bg-white px-4 py-5 md:mt-0 md:w-[440px]'
              >
                <div>
                  <h1 className='mb-4 text-2xl font-bold'>
                    Delete account forever?
                  </h1>
                  <p className='mb-7 text-sm'>
                    This action cannot be undone. You will no longer have access
                    to your account content or any associated solutions.
                  </p>
                </div>
                <div className='flex items-center gap-4'>
                  <Button
                    type='submit'
                    size='md'
                    variant='secondary'
                    className='w-full'
                    onClick={() => setOpenDeleteModal(false)}
                  >
                    No, Cancel
                  </Button>
                  <Button
                    type='submit'
                    size='md'
                    variant='primary'
                    className='w-full'
                    // TODO: delete account handler
                    onClick={() => handleDeleteAccount()}
                  >
                    Yes, Delete
                  </Button>
                  {/* TODO: add delete account handler */}
                </div>
              </Modal>
            </div>
          )
        ) : (
          null
        )}
        {!toggleEdit && (
          <div className='mb-6 mt-8 space-y-4 md:mb-8 md:mt-12'>       
            {
              currentUser?.role?.name !== 'customer' ? (
                solutions.length > 0 ? (
                  <div className='flex flex-col gap-6 pt-10'>
                    {solutions.map((solution, index) => (
                      <ProfilePageSolutionCard solution={solution} index={index} />
                    ))}
                  </div>
                ) : (
                  <div>
                    <p className='text-black-blue text-center'>
                      You have no solutions yet
                    </p>
                  </div>
                )
              ) : null
            }
          {currentUser?.role?.name !== "customer" && <MarketplaceRow />}
          </div>
        )}
      </div>
    </Layout>
  )
}

export default AccountManagement
