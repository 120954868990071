export default function SolutionLeadsMessage({ logo, heading, subheading }) {
  return (
    <div className='flex gap-8 py-6'>
      <img src={logo} alt='Logo' />
      <div className='flex w-full flex-col md:w-[47%]'>
        <p className='text-xl font-semibold text-outer-space sm:w-auto'>
          {heading}
        </p>
        <p className='text-blue-smoke'>
          {subheading}
        </p>
      </div>
    </div>
  )
}