import { Suspense, useState } from "react"
import { lazily } from 'react-lazily'
const { VideoSection, EditVideoSection } = lazily(() => import('../../atoms/OfficialMediaSection/VideoSection'))
const { ScreenshotSection, EditScreenshotSection } = lazily(() => import('../../atoms/OfficialMediaSection/ScreenshotSection'))
const { DownloadSection, EditDownloadSection } = lazily(() => import('../../atoms/OfficialMediaSection/DownloadSection'))
import editPencilBlackIcon from '../../../../assets/images/edit-pencil.svg'
import { router, useForm } from "@inertiajs/react"
import Button from "@/components/atoms/buttons/Button"
import axios from "axios"
import { useToken } from '@/components/atoms/forms/Form';
import { toast } from "react-toastify"
import { Loader } from "@/components/atoms/widgets/Loader"

type OfficialMediaSectionProps = {
  solution: schema.Solution;
  documents: schema.Document[];
  current_user?: schema.User;
  is_claimed: boolean;
  solutionOwner: schema.User;
  videos: schema.Video[];
  screenshots: schema.Screenshot[];
  isRmcAdmin: boolean;
};

const OfficialMediaSection = ({ solution, documents, current_user, solutionOwner, is_claimed, videos, screenshots, isRmcAdmin }: OfficialMediaSectionProps) => {
  const [editMediaSection, setEditMediaSection] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const token = useToken();

  const { data, setData } = useForm({
    documents_attributes: documents,
    screenshots_attributes: screenshots,
    videos_attributes: videos
  });
  const handleEditClick = () => {
    setEditMediaSection(!editMediaSection);
  }

  const handleBack = () => {
    router.visit(`/solutions/${solution.slug}`);
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true)
      const formData = new FormData();

      data.documents_attributes.forEach((document, index) => {
        if (document.id) {
          formData.append(`solution[documents_attributes][${index}][id]`, document.id)
        }
        formData.append(
          `solution[documents_attributes][${index}][title]`,
          document.title
        );
        if(document.doc_file_changed && !document._destroy){
          formData.append(
            `solution[documents_attributes][${index}][document_file]`,
            document.document_file
          );
        }
        if(document.image_url === null && document.image !== null ) {
          formData.append(
            `solution[documents_attributes][${index}][image]`,
            document.image
          );
        }
        if(document._destroy){
          formData.append(
            `solution[documents_attributes][${index}][_destroy]`,
            document._destroy
          );
        }
      });

      data.screenshots_attributes.forEach((screenshot, index) => {
        if (screenshot.id) {
          formData.append(`solution[screenshots_attributes][${index}][id]`, screenshot.id)
        }
        formData.append(
          `solution[screenshots_attributes][${index}][description]`,
          screenshot.description
        );
        if(screenshot.image_url === null && screenshot.imagename !== "") {
          formData.append(
            `solution[screenshots_attributes][${index}][image]`,
            screenshot.image
          );
        }
        if(screenshot._destroy){
          formData.append(
            `solution[screenshots_attributes][${index}][_destroy]`,
            screenshot._destroy
          );
        }
      });

      data.videos_attributes.forEach((video, index) => {
        if (video.id) {
          formData.append(`solution[videos_attributes][${index}][id]`, video.id)
        }
        formData.append(
          `solution[videos_attributes][${index}][title]`,
          video.title
        )

        if(video.doc_file_changed){
          formData.append(
            `solution[videos_attributes][${index}][video_file]`,
            video.video_file
          )
        }
        if(video._destroy){
          formData.append(
            `solution[videos_attributes][${index}][_destroy]`,
            video._destroy
          )
        }
      })

      const response = await axios.put(`/solutions/${solution.id}`, formData, {
        headers: {
          'X-CSRF-Token': token,
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.status === 200) {
        toast(
          <div>
            <span className="font-bold">Success! </span>Your changes have been
            saved
          </div>,
          {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            className:
              'bg-teal-tint-2 text-md text-black p-4 md:p-6 md:rounded-xl md:w-[400px] md:right-[80px]',
            bodyClassName: 'p-0 min-w-[324px]',
          }
        );
        setTimeout(() => {
          router.visit(`/solutions/${solution.slug}`);
        }, 1000);
      }
    } catch (error) {
      setIsLoading(true)

      toast(
        <div>
          <span className="font-bold">Error! </span>
          Something Went Wrong! Try Again.
        </div>,
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          className:
            'bg-red-200 text-md text-black p-4 md:p-6 md:rounded-xl md:w-[400px] md:right-[80px]',
          bodyClassName: 'p-0 min-w-[324px]',
        }
      );
    } finally {
      setIsLoading(false)
    }
  };
  return (
    <div className="sm:mt-[6rem] mt-10">
      {(is_claimed || isRmcAdmin) && (
        <div>
          {
            <div className="flex justify-between items-center">
              <h2 className="text-xl font-bold">Official Media</h2>
              {
                current_user && (current_user?.id == solutionOwner?.id || isRmcAdmin) &&
                <div>
                  {!editMediaSection ? (
                    <div className="rounded-full bg-white p-2">
                      <img
                        src={editPencilBlackIcon}
                        alt="pencil icon"
                        onClick={handleEditClick}
                        className="w-[35px] stroke-white"
                        style={{ cursor: 'pointer' }}
                      />
                    </div>
                  ) : (
                    <div className="ml-auto flex gap-2">
                      <Button
                        type="submit"
                        size="md"
                        variant="teal"
                        isLoading={isLoading}
                        className="flex h-12 w-20 items-center justify-center rounded-[12px] px-4 py-3"
                        onClick={handleSubmit}
                      >
                        {isLoading ? 'Saving...' : 'Save'}
                      </Button>
                      <Button
                        onClick={() => handleBack()}
                        type="button"
                        size="md"
                        variant="gray"
                        isLoading={isLoading}
                        className="flex h-12 w-20 items-center justify-center rounded-[12px] px-4 py-3"
                      >
                        Cancel
                      </Button>
                    </div>
                  )}
                </div>
              }
            </div>
          }
          <div className="rounded-xl border-[1.5px] mt-4">
            <Suspense fallback={<Loader headline={"Official Media Section"}/>}>
              {
                editMediaSection ? (
                  <>
                    <EditVideoSection solution={solution} data={data} setData={setData} ></EditVideoSection>
                    <EditScreenshotSection solution={solution} screenshots={screenshots} handleSubmit={handleSubmit} data={data} setData={setData} />
                    <EditDownloadSection solution={solution} documents={documents} handleSubmit={handleSubmit} data={data} setData={setData}></EditDownloadSection>
                  </>
                ) : (
                  <>
                    <VideoSection videos={videos} current_user={current_user} is_claimed={is_claimed} solutionOwner={solutionOwner} isRmcAdmin = {isRmcAdmin}></VideoSection>
                    <ScreenshotSection screenshots={screenshots} current_user={current_user} is_claimed={is_claimed} solutionOwner={solutionOwner} isRmcAdmin = {isRmcAdmin} />
                    <DownloadSection documents={documents} current_user={current_user} is_claimed={is_claimed} solutionOwner={solutionOwner} isRmcAdmin = {isRmcAdmin}></DownloadSection>
                  </>
                )
              }
            </Suspense>
          </div>
        </div>
      )}
    </div>
  )
}

export default OfficialMediaSection;