import { MLP_PROMO_LINK } from '@/utils/constants'
import Button from '../buttons/Button'

type SolutionShowProps = {
  heading: string
  paragraph: string
  showUpgradeButton?: boolean
}

export default function EmptySection({
  heading,
  paragraph,
  showUpgradeButton,
}: SolutionShowProps) {
  return (
    <>
      {showUpgradeButton ? (
        <div className='flex flex-col items-center justify-center p-[1.5rem] sm:p-[2rem] gap-[1rem] self-stretch border border-gray-300 bg-light-gray rounded-lg'>
          <div className='text-lg font-bold leading-normal text-gray-900'>
            {heading}
          </div>
          <div className='text-custom-dark text-base font-medium leading-relaxed'>
            {paragraph}
          </div>
          <Button
            type='button'
            variant='darkBlue'
            className='flex p-[0.4375rem] px-[1rem] justify-center items-center gap-[0.5rem] rounded-md'
            onClick={() => window.location.href = MLP_PROMO_LINK}
          >
            Upgrade Account
          </Button>
        </div>
      ) : (
        <div className='box-content flex h-full max-h-14 flex-col items-center justify-center rounded-lg border border-gray-300 bg-light-gray p-[32px] md:p-[32px] lg:p-[32px] xl:p-[32px] max-md-sm:py-[32px] max-md-sm:pr-[12px] max-md-sm:pl-[24px]'>
          <div className='md:flex md:flex-col md:items-center md:justify-center md:text-center'>
            <div className='text-lg font-bold leading-normal text-gray-900'>
              {heading}
            </div>
            <div className='text-custom-dark text-base font-medium leading-relaxed'>
              {paragraph}
            </div>
          </div>
        </div>
      )}
    </>
  )
}
