import clsx from 'clsx'

export const BuyerGuideNavyPanel = ({ sectionRefs , navyPanelQuestions }) => {

  const handleScroll = (index) => {
    sectionRefs[index].current.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    })
  }
  return (
    <div className='sticky top-[120px] flex h-[529px] w-[469px] flex-col rounded-2xl bg-[#031931] text-white'>
          <h2 className='flex justify-center p-6 text-xl font-semibold'>Applicant Tracking Software (ATS) Topics</h2>
          <div className='px-2'>
            {navyPanelQuestions.map((question, index) => (
              <p
                key={index}
                onClick={() => handleScroll(index)}
                className={clsx(
                  'ml-4 flex cursor-pointer items-center border-b-[1px] border-r-2 border-b-ship-grey border-r-transparent pb-4 pl-4 pr-3 pt-5',
                  {
                    'border-b-transparent': index === 5,
                    'border-b-ship-grey': index !== 5  
                  }
                )}
              >
                {question}
              </p>
            ))}
          </div>
        </div>
  )
}