import Button from '@/components/atoms/buttons/Button'
import { BOOK_CALL_LINK } from '@/utils/constants'

const BookACallCta = () => {
  return (
    <div className='flex flex-col gap-2 rounded-xl border border-tropical-blue bg-titan-white px-5 py-3 text-blue-zodiac'>
      <p className='pr-10 text-md font-semibold'>Want to find out how to get the most from your account?</p>
      <p className='text-xs font-medium leading-5'>Book a call with one of our team today</p>
      <Button
        variant='secondary'
        className='mt-1 max-w-[140px] px-4 py-3 text-md font-medium'
        onClick={() => window.location.href = BOOK_CALL_LINK }>
        Book Call
      </Button>
    </div>
  )
}

export default BookACallCta