import { EmailRegex } from '@/utils/constants'

const leadSubmissionAttributes = (userLoggedIn) => {
  if(userLoggedIn) return ['current_timeline', 'software_acquisition_role']
  else return ['first_name', 'last_name', 'company', 'job_title', 'email', 'current_timeline', 'software_acquisition_role']
}

const newsletterSubmissionAttributes = () => {
  return ['first_name', 'last_name', 'company', 'job_title', 'email']
}

export const formValuesFilled = (formValues, formType, userLoggedIn) =>{
  const isNonEmptyAndValid = (value: string) => value && value.length >= 2

  const requiredFields = formType === 'newsletter'
    ? newsletterSubmissionAttributes()
    : leadSubmissionAttributes(userLoggedIn)

    const allFieldsValid = requiredFields.every(field => isNonEmptyAndValid(formValues[field]))
    const isEmailValid = EmailRegex.test(formValues.email) || userLoggedIn
  
    return allFieldsValid && isEmailValid  
}
